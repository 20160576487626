import React from "react";

const Svg = () => {
  return (
    <div className="serviceSvg">
      <svg
        width="3000"
        height="2000"
        viewBox="0 0 3000 2000"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="5127311-ai 1" clipPath="url(#clip0_114_2)">
          <g id="BACKGROUND">
            <g id="&#60;Group&#62;">
              <path
                id="&#60;Path&#62;"
                d="M3000 2000H0V0H3000V2000Z"
                fill="#002E94"
              />
            </g>
          </g>
          <g id="OBJECTS">
            <g id="&#60;Group&#62;_2">
              <path
                id="&#60;Path&#62;_2"
                d="M2838.8 1506.8C2734.8 1664.8 2556.4 1757.6 2367.2 1757.6H798.4C482.8 1757.6 306 1388.8 510.4 1181.2C715.2 973.6 534.4 796 850 629.6C1155.6 468.4 1260 812 1541.6 587.2C1823.2 362.4 2232.8 383.6 2322.8 612.8C2413.2 842 2392 922.4 2582.8 973.6C2774 1024.4 2960.8 1313.2 2846 1495.6C2843.6 1499.33 2841.2 1503.07 2838.8 1506.8Z"
                fill="#BDCAE0"
              />
            </g>
            <g id="&#60;Group&#62;_3">
              <g id="&#60;Group&#62;_4">
                <g id="&#60;Group&#62;_5">
                  <path
                    id="&#60;Path&#62;_3"
                    d="M2769.2 1570.8C2603.6 1584.8 2492.4 1894.8 2079.2 1708.4C2109.33 1618.27 2192.13 1545.07 2327.6 1488.8C2463.07 1432.8 2610.27 1460.13 2769.2 1570.8Z"
                    fill="#CDDCF4"
                  />
                </g>
                <g id="&#60;Group&#62;_6">
                  <path
                    id="&#60;Path&#62;_4"
                    d="M2135.6 1685.2C2178.8 1662.8 2223.2 1643.6 2269.2 1626.8C2280.4 1622.4 2292 1618.4 2303.6 1614.4C2315.2 1610.4 2326.8 1607.2 2338.4 1603.6C2350 1599.6 2361.6 1596.8 2373.6 1593.6C2379.6 1592 2385.2 1590.4 2391.2 1588.8L2409.2 1584.4L2426.8 1580.4L2444.8 1576.8L2462.8 1573.2C2468.8 1572 2474.8 1571.2 2480.8 1570.4C2492.8 1568.4 2505.2 1566.4 2517.2 1565.6C2529.2 1564 2541.6 1562.4 2553.6 1562C2602.4 1558.4 2651.6 1559.2 2699.6 1567.2C2650.8 1566 2602.8 1569.2 2554.8 1574.8L2546 1576L2537.2 1577.2L2519.2 1580C2507.2 1581.6 2495.2 1584 2483.6 1586C2477.6 1587.2 2471.6 1588 2465.6 1589.2L2448 1592.8L2430.4 1596.4L2412.8 1600.8L2395.2 1604.8C2389.2 1606 2383.6 1607.6 2377.6 1609.2L2360 1613.6C2354.4 1615.2 2348.4 1617.2 2342.8 1618.8C2331.2 1621.6 2319.6 1624.8 2308 1628.4C2296.4 1632 2284.8 1635.2 2273.2 1638.8C2227.2 1653.2 2181.2 1669.2 2135.6 1685.2V1685.2Z"
                    fill="#B0BDD1"
                  />
                </g>
              </g>
              <g id="&#60;Group&#62;_7">
                <g id="&#60;Group&#62;_8">
                  <path
                    id="&#60;Path&#62;_5"
                    d="M2789.2 1043.6C2587.6 1187.6 2680.8 1670.8 2008 1746.8C1977.87 1607.87 2028.4 1450.67 2159.6 1275.2C2290.8 1099.47 2500.67 1022.27 2789.2 1043.6Z"
                    fill="#DBE9FF"
                  />
                </g>
                <g id="&#60;Group&#62;_9">
                  <path
                    id="&#60;Path&#62;_6"
                    d="M2052.4 1688.8C2082 1625.2 2118 1564.8 2158.8 1507.6C2199.6 1450 2245.2 1396 2294.8 1346C2344.4 1296 2398.4 1250 2456.4 1210.4C2514.8 1170.8 2577.2 1136.4 2644 1115.2C2582 1147.6 2524.8 1186.4 2470 1228.8C2415.6 1271.6 2364.4 1317.6 2315.6 1366.8C2266.8 1415.6 2220.8 1467.6 2177.2 1521.2C2133.6 1575.2 2092.4 1631.2 2052.4 1688.8V1688.8Z"
                    fill="#CDDCF4"
                  />
                </g>
              </g>
              <g id="&#60;Group&#62;_10">
                <g id="&#60;Group&#62;_11">
                  <path
                    id="&#60;Path&#62;_7"
                    d="M1059.6 1477.2C1190.8 1542.8 1268.27 1621.6 1292 1713.6C866.8 1870.4 777.6 1553.6 613.6 1528C779.733 1428.53 928.4 1411.6 1059.6 1477.2V1477.2Z"
                    fill="#CDDCF4"
                  />
                </g>
                <g id="&#60;Group&#62;_12">
                  <path
                    id="&#60;Path&#62;_8"
                    d="M1237.6 1686.4C1192.8 1667.2 1148.4 1648.4 1103.2 1630.8C1092 1626 1080.8 1622 1069.6 1617.6C1058.4 1613.2 1046.8 1609.6 1035.6 1605.6C1029.6 1603.6 1024 1601.2 1018.4 1599.6L1001.2 1593.6C995.6 1591.6 990 1589.6 984 1588L966.8 1582.8L949.6 1577.2L932.4 1572.4L914.8 1567.6C909.2 1566 903.2 1564.4 897.2 1562.8C885.6 1560 874 1556.8 862.4 1554.4L844.4 1550.4L835.6 1548.4L826.8 1546.8C779.6 1537.6 731.6 1531.6 683.2 1529.2C731.6 1524.4 780.8 1527.2 829.2 1534C841.2 1535.2 853.2 1538 865.2 1540C877.2 1542 889.2 1544.8 901.2 1547.6C907.2 1548.8 912.8 1550.4 918.8 1552L936.4 1556.8L954.4 1561.6L971.6 1566.8L989.2 1572.4C994.8 1574 1000.8 1576.4 1006.4 1578.4C1018 1582.4 1029.6 1586 1040.8 1590.8C1052.4 1595.2 1063.6 1599.2 1074.8 1604C1086 1608.8 1097.2 1613.6 1108.4 1618.8C1152.8 1638.8 1196 1661.2 1237.6 1686.4V1686.4Z"
                    fill="#B0BDD1"
                  />
                </g>
              </g>
              <g id="&#60;Group&#62;_13">
                <g id="&#60;Group&#62;_14">
                  <path
                    id="&#60;Path&#62;_9"
                    d="M1304 1283.2C1416.8 1458.4 1454.27 1610.93 1416.4 1740.8C783.199 1623.6 903.999 1171.6 722.399 1021.6C997.599 1020.53 1191.47 1107.73 1304 1283.2V1283.2Z"
                    fill="#DBE9FF"
                  />
                </g>
                <g id="&#60;Group&#62;_15">
                  <path
                    id="&#60;Path&#62;_10"
                    d="M1378 1682.4C1343.6 1625.2 1308.4 1569.6 1270.8 1515.6C1232.8 1461.6 1192.8 1409.6 1149.6 1360C1106.8 1310.4 1061.2 1263.2 1012.4 1218.8C963.599 1174.8 911.999 1134.4 855.199 1099.2C917.599 1123.6 974.799 1160 1027.2 1201.6C1080 1242.8 1128 1290 1171.6 1340.8C1215.6 1391.6 1255.2 1446 1289.6 1503.2C1324.4 1560.4 1354.8 1620 1378 1682.4V1682.4Z"
                    fill="#CDDCF4"
                  />
                </g>
              </g>
            </g>
            <g id="&#60;Group&#62;_16">
              <g id="&#60;Group&#62;_17">
                <g id="&#60;Group&#62;_18">
                  <path
                    id="&#60;Path&#62;_11"
                    d="M1868 1527.2L1824 1566.4C1812.27 1607.2 1791.73 1636 1762.4 1652.8C1693.6 1658.67 1666 1639.73 1679.6 1596C1700 1530.4 1721.6 1528 1734.4 1520.8C1747.2 1514 1768 1469.2 1790.8 1442L1868 1527.2Z"
                    fill="#F6A397"
                  />
                </g>
                <path
                  id="&#60;Path&#62;_12"
                  d="M1711.2 1645.6C1733.33 1638.4 1753.73 1624.8 1772.4 1604.8C1791.33 1584.8 1802 1557.07 1804.4 1521.6L1818 1547.2L1845.2 1518L1858.4 1516.8L1868 1527.2L1824 1566.4C1812.27 1607.2 1791.73 1636 1762.4 1652.8C1743.47 1654.4 1726 1652.8 1710 1648C1710.27 1646.93 1710.67 1646.13 1711.2 1645.6Z"
                  fill="#D65863"
                />
                <g id="&#60;Group&#62;_19">
                  <path
                    id="&#60;Path&#62;_13"
                    d="M1988.4 1270.8C2008.4 1309.6 2023.2 1424.4 1980 1456.8C1951.47 1478.4 1912.53 1517.73 1863.2 1574.8C1814 1548.8 1752.4 1442.4 1758.8 1432.4C1764.8 1422.4 1863.2 1309.6 1878.8 1278.4C1894.4 1246.8 1913.2 1230.4 1944.8 1207.2C1960.8 1224 1975.33 1245.2 1988.4 1270.8V1270.8Z"
                    fill="#5470D3"
                  />
                </g>
                <path
                  id="&#60;Path&#62;_14"
                  d="M1943.6 1414.4V1350.4C1949.2 1348.27 1954.13 1354.4 1958.4 1368.8C1961.2 1377.6 1960.8 1386.4 1959.6 1394.4L2007.6 1358.4C2009.6 1398.8 2003.2 1439.6 1980 1456.8C1951.47 1478.4 1912.53 1517.73 1863.2 1574.8C1850.4 1568.4 1836.8 1556 1824 1541.6C1854 1511.6 1911.6 1453.6 1943.6 1414.4Z"
                  fill="#314EC6"
                />
                <g id="&#60;Group&#62;_20">
                  <path
                    id="&#60;Path&#62;_15"
                    d="M1648 1637.2C1650.93 1642.27 1652.8 1659.07 1653.6 1687.6C1640.27 1684.93 1632.27 1681.47 1629.6 1677.2C1625.6 1670.8 1624 1654.4 1624 1644.4C1624 1634 1630 1630 1638.4 1627.6C1642.13 1628.67 1645.33 1631.87 1648 1637.2Z"
                    fill="#F6A397"
                  />
                </g>
                <path
                  id="&#60;Path&#62;_16"
                  d="M1629.6 1677.2C1628.4 1675.6 1627.6 1672.8 1626.8 1670C1631.2 1674 1632.8 1674 1638 1674.8L1653.6 1679.6C1653.6 1682.8 1653.6 1685.47 1653.6 1687.6C1640.27 1684.93 1632.27 1681.47 1629.6 1677.2V1677.2Z"
                  fill="#D65863"
                />
                <g id="&#60;Group&#62;_21">
                  <path
                    id="&#60;Path&#62;_17"
                    d="M1679.6 1689.2C1681.2 1692.4 1670.8 1694.4 1664.8 1693.2C1658.8 1692.4 1641.6 1679.2 1638 1674.4C1634.4 1670 1638 1641.2 1638 1630.8C1638 1620.4 1647.6 1612.8 1657.6 1612C1671.47 1661.33 1678.8 1687.07 1679.6 1689.2V1689.2Z"
                    fill="#F6A397"
                  />
                </g>
                <path
                  id="&#60;Path&#62;_18"
                  d="M1636.4 1666.8C1641.47 1672.4 1650.4 1674.93 1663.2 1674.4C1668.8 1680.53 1672 1684.53 1672.8 1686.4C1673.2 1688 1676.8 1690.4 1677.2 1692.4C1674 1693.6 1668.8 1694 1664.8 1693.2C1658.8 1692.4 1642 1679.6 1638 1674.8L1636.4 1666.8V1666.8Z"
                  fill="#D65863"
                />
                <g id="&#60;Group&#62;_22">
                  <path
                    id="&#60;Path&#62;_19"
                    d="M1702 1652C1701.73 1665.87 1696 1680.93 1684.8 1697.2C1679.73 1697.2 1675.6 1695.6 1672.4 1692.4C1667.2 1687.2 1665.2 1679.2 1661.2 1670C1657.2 1660.4 1656.8 1624.4 1657.6 1612C1658.8 1599.6 1664.4 1596.8 1695.2 1594.8C1700.27 1619.33 1702.53 1638.4 1702 1652V1652Z"
                    fill="#F6A397"
                  />
                </g>
                <path
                  id="&#60;Path&#62;_20"
                  d="M1677.6 1676.8C1679.2 1668.4 1682.4 1659.2 1682.8 1631.2C1683.2 1620.4 1692.8 1676 1688.4 1691.2C1687.07 1693.6 1685.87 1695.6 1684.8 1697.2C1679.73 1697.2 1675.6 1695.6 1672.4 1692.4C1667.2 1687.2 1665.2 1679.2 1661.2 1670C1660.93 1669.2 1660.67 1668.4 1660.4 1667.6C1662.4 1672.8 1668.8 1676.4 1677.6 1676.8Z"
                  fill="#D65863"
                />
                <g id="&#60;Group&#62;_23">
                  <path
                    id="&#60;Path&#62;_21"
                    d="M1762 1657.6C1760 1674.4 1737.6 1680.4 1735.2 1689.2C1732.8 1697.6 1734.4 1705.2 1730.4 1707.6C1726 1709.6 1708 1699.6 1708.8 1688.4C1705.87 1694.8 1700.93 1698.53 1694 1699.6C1683.6 1701.6 1681.2 1694 1682.8 1682.4C1684.8 1671.2 1684.4 1665.2 1684 1651.6C1683.6 1638.4 1680.4 1625.6 1686.4 1610.8C1692.4 1596 1700.8 1582.8 1717.2 1585.2C1733.6 1587.6 1743.2 1608.4 1752.4 1615.6C1761.2 1622.4 1764 1638.4 1762 1657.6V1657.6Z"
                    fill="#F6A397"
                  />
                </g>
                <path
                  id="&#60;Path&#62;_22"
                  d="M1708.8 1688.4C1710 1666.8 1711.2 1649.2 1711.2 1645.6C1711.2 1642 1710 1619.2 1718 1617.6C1719.2 1617.6 1737.2 1628.8 1737.6 1636.4C1738 1644.4 1737.6 1658 1732 1662C1728.27 1664.67 1720.53 1673.47 1708.8 1688.4Z"
                  fill="#D65863"
                />
              </g>
              <g id="&#60;Group&#62;_24">
                <g id="&#60;Group&#62;_25">
                  <path
                    id="&#60;Path&#62;_23"
                    d="M2407.2 1392C2400 1450.4 2386 1736 2380.4 1744C2376.93 1749.6 2242.53 1752.13 1977.2 1751.6C1964.93 1632.4 1958.8 1546.4 1958.8 1493.6C1958.4 1341.6 1908.8 1269.2 1934 1218C1941.6 1202.8 1985.2 1187.6 2020 1171.6C2054.4 1156 2160 1106.8 2186 1113.2C2212 1119.6 2338.8 1168.8 2372.4 1188.4C2405.6 1208 2416.8 1212 2449.2 1253.2C2425.73 1306.53 2411.73 1352.8 2407.2 1392V1392Z"
                    fill="#5470D3"
                  />
                </g>
                <g id="&#60;Group&#62;_26">
                  <path
                    id="&#60;Path&#62;_24"
                    d="M1935.2 1298C1932.4 1288.4 1930.8 1278.8 1929.2 1268.8C1927.6 1259.2 1926.8 1249.2 1926.8 1239.2C1930 1248.8 1931.6 1258.4 1933.2 1268.4C1934.4 1278 1935.6 1288 1935.2 1298Z"
                    fill="#314EC6"
                  />
                </g>
                <g id="&#60;Group&#62;_27">
                  <path
                    id="&#60;Path&#62;_25"
                    d="M2084.4 1238.4C2068.8 1232.4 2032.4 1242.4 2025.6 1244.8C2019.2 1247.2 2029.6 1218.8 2033.6 1215.2C2037.6 1211.6 2043.6 1203.6 2055.6 1207.2C2067.6 1210.8 2078 1224.8 2079.6 1229.2C2080.67 1232.13 2082.27 1235.2 2084.4 1238.4V1238.4Z"
                    fill="#314EC6"
                  />
                </g>
                <g id="&#60;Group&#62;_28">
                  <path
                    id="&#60;Path&#62;_26"
                    d="M2212.4 1262C2206.8 1262 2174.8 1247.2 2167.2 1243.6C2159.6 1240 2124.8 1238 2101.6 1244.8C2131.47 1224.53 2155.07 1214.4 2172.4 1214.4C2198.8 1214.8 2208.4 1224.4 2210.8 1232.4C2212.13 1238 2212.67 1247.87 2212.4 1262V1262Z"
                    fill="#314EC6"
                  />
                </g>
                <g id="&#60;Group&#62;_29">
                  <path
                    id="&#60;Path&#62;_27"
                    d="M2041.2 1687.6C2045.6 1683.6 2305.6 1692 2310.8 1664.8C2314.27 1646.67 2317.2 1621.2 2319.6 1588.4C2298.53 1599.33 2272.93 1602.53 2242.8 1598C2273.2 1588 2316.8 1538.4 2319.2 1523.6C2323.47 1493.73 2317.47 1437.33 2301.2 1354.4L2319.2 1319.2L2339.6 1307.6C2365.73 1320.67 2390 1339.07 2412.4 1362.8C2410 1372.8 2408 1382.4 2407.2 1392C2400.4 1446.8 2387.6 1704 2381.6 1740C2301.2 1743.2 2155.2 1748.4 2057.6 1751.6C2026.13 1751.87 1999.33 1751.87 1977.2 1751.6C1976.67 1736.13 1976 1717.07 1975.2 1694.4C2002.4 1692.8 2038.8 1690.4 2041.2 1687.6V1687.6Z"
                    fill="#314EC6"
                  />
                </g>
                <g id="&#60;Group&#62;_30">
                  <path
                    id="&#60;Path&#62;_28"
                    d="M2055.2 1212C2043.2 1213.2 2027.2 1246.4 2021.6 1246C2015.6 1245.2 2028.4 1194.4 2032.8 1181.6C2037.6 1168.8 2044.8 1155.6 2062 1142.4C2079.6 1129.6 2165.2 1097.2 2198 1103.2C2231.2 1109.6 2230 1155.6 2230 1200.4C2230 1245.2 2214 1263.6 2210.4 1260C2206.8 1256.4 2202.8 1234.8 2184.8 1226.4C2167.2 1218.4 2122 1242.4 2094 1246C2086.4 1246.8 2067.2 1210.8 2055.2 1212V1212Z"
                    fill="#5470D3"
                  />
                </g>
                <path
                  id="&#60;Path&#62;_29"
                  d="M2078 1205.6C2070.8 1188.8 2063.2 1174 2063.2 1162C2063.6 1150.4 2066.4 1142.4 2079.2 1138.4C2092.4 1134.8 2098 1142 2098.8 1159.6C2099.07 1171.6 2092.13 1186.93 2078 1205.6V1205.6Z"
                  fill="#314EC6"
                />
                <path
                  id="&#60;Path&#62;_30"
                  d="M2192.8 1128.4C2190.93 1125.47 2191.47 1118 2194.4 1106C2201.87 1108.67 2206.53 1112 2208.4 1116C2210.8 1121.6 2207.6 1149.2 2180.4 1174C2190.27 1146.53 2194.4 1131.33 2192.8 1128.4Z"
                  fill="#314EC6"
                />
                <g id="&#60;Group&#62;_31">
                  <path
                    id="&#60;Path&#62;_31"
                    d="M2137.2 1234C2143.6 1230.8 2150.4 1228.4 2157.6 1226.8C2164.8 1224.8 2172 1223.6 2179.6 1224C2183.6 1224.4 2187.6 1225.2 2190.8 1228C2193.6 1230.4 2196 1233.2 2198.4 1236C2203.6 1241.6 2206.8 1248.4 2210 1254.8C2210.8 1256.4 2212 1258 2212.4 1258.4C2212.4 1258.4 2212.4 1258.4 2212.8 1258C2213.33 1257.73 2213.87 1257.2 2214.4 1256.4C2216.4 1254 2218 1250.8 2219.6 1247.6C2223.2 1241.2 2224.8 1234.4 2226.4 1227.2C2227.6 1220.4 2228.4 1213.2 2228.8 1205.6C2229.6 1198.4 2229.6 1191.2 2230 1184C2231.2 1198.4 2231.2 1213.2 2229.2 1228C2228.4 1235.2 2226.4 1242.4 2223.2 1249.2C2221.6 1252.4 2220 1256 2217.6 1258.8C2216.8 1259.6 2216 1260.4 2215.2 1261.2C2214.67 1261.73 2214 1262.13 2213.2 1262.4C2212.4 1262.4 2211.6 1262.4 2210.8 1262C2209.2 1261.2 2208.4 1260 2208 1259.2C2207.47 1258.4 2206.93 1257.6 2206.4 1256.8C2203.2 1250 2200 1243.6 2195.6 1238.4C2193.6 1235.6 2190.8 1232.8 2188.4 1230.4C2186 1228.4 2182.8 1227.6 2179.2 1227.2C2172.4 1226.4 2165.2 1227.2 2158 1228.4C2150.8 1230 2144 1231.6 2137.2 1234V1234Z"
                    fill="#314EC6"
                  />
                </g>
                <g id="&#60;Group&#62;_32">
                  <path
                    id="&#60;Path&#62;_32"
                    d="M2072.8 1224.4C2069.6 1222 2066.8 1219.2 2063.2 1216.8C2061.6 1216 2060 1214.8 2058.4 1214C2057.6 1213.73 2056.8 1213.47 2056 1213.2C2055.47 1213.2 2055.07 1213.33 2054.8 1213.6C2054.8 1213.6 2054.4 1213.6 2054 1214C2047.2 1218 2042 1223.6 2037.6 1230.4C2035.2 1233.6 2033.2 1237.2 2030.8 1240.4C2029.2 1242 2028 1243.6 2026 1244.8C2025.2 1245.6 2024.4 1246.4 2022.4 1246.4C2021.6 1246.8 2020.8 1246.4 2020 1245.6C2019.2 1245.2 2018.8 1244.4 2018.8 1243.6C2018 1241.2 2018.4 1239.2 2018.4 1237.2C2018.67 1235.07 2019.07 1233.07 2019.6 1231.2C2020.4 1227.2 2021.6 1223.33 2023.2 1219.6C2022.4 1223.6 2021.6 1227.6 2021.6 1231.6C2021.33 1233.47 2021.2 1235.33 2021.2 1237.2C2021.2 1239.2 2021.2 1241.2 2021.6 1242.8C2021.87 1243.07 2022 1243.2 2022 1243.2C2022 1243.2 2022 1243.2 2022.4 1243.2C2022.4 1243.2 2023.2 1242.8 2024 1242C2025.2 1241.2 2026.4 1239.6 2027.6 1238C2030 1234.8 2032 1231.6 2034.4 1228C2036.8 1224.8 2039.2 1221.6 2042.4 1218.4C2045.2 1215.6 2048.8 1213.2 2052.4 1211.2C2052.8 1211.2 2053.6 1210.8 2054.4 1210.4C2054.93 1210.4 2055.6 1210.4 2056.4 1210.4C2057.47 1210.67 2058.53 1211.07 2059.6 1211.6C2061.47 1212.67 2063.07 1214 2064.4 1215.6C2067.6 1218.27 2070.4 1221.2 2072.8 1224.4V1224.4Z"
                    fill="#314EC6"
                  />
                </g>
                <g id="&#60;Group&#62;_33">
                  <path
                    id="&#60;Path&#62;_33"
                    d="M2074.8 1068C2075.6 1035.6 2095.2 987.2 2127.6 987.2C2160 987.2 2194 1012 2194.4 1044.4C2195.2 1076.8 2198.4 1108 2199.2 1111.2C2203.2 1135.6 2190.8 1164.8 2172.4 1183.6C2154 1202 2140.4 1202.4 2130 1215.2C2123.07 1223.47 2111.2 1231.47 2094.4 1239.2C2085.07 1221.87 2079.6 1210.67 2078 1205.6C2076 1199.2 2078.8 1163.6 2076 1136.8C2074.8 1125.6 2074.4 1114.8 2074.4 1103.6C2074 1093.6 2074.4 1081.2 2074.8 1068V1068Z"
                    fill="#F6A397"
                  />
                </g>
                <g id="&#60;Group&#62;_34">
                  <path
                    id="&#60;Path&#62;_34"
                    d="M2194.4 1044.4C2195.2 1076.8 2198.4 1108 2199.2 1111.2C2200.4 1118.8 2200 1126.8 2198.4 1134.8C2191.2 1136 2098.8 1138.4 2075.2 1127.2C2074.67 1119.47 2074.4 1111.6 2074.4 1103.6C2074 1093.6 2074.4 1081.2 2074.8 1068C2075.2 1054 2078.8 1037.6 2085.6 1023.2L2161.2 996C2180 1006 2194 1023.6 2194.4 1044.4V1044.4Z"
                    fill="#D65863"
                  />
                </g>
              </g>
              <g id="&#60;Group&#62;_35">
                <g id="&#60;Group&#62;_36">
                  <path
                    id="&#60;Path&#62;_35"
                    d="M2067.2 975.2C2067.2 1011.6 2047.2 1041.2 2017.6 1041.2C1958.4 1041.2 1950 1000.8 1950 975.2C1950 947.2 1968.4 909.6 2017.6 909.6C2047.2 909.6 2067.2 939.2 2067.2 975.2Z"
                    fill="#504984"
                  />
                </g>
                <g id="&#60;Group&#62;_37">
                  <path
                    id="&#60;Path&#62;_36"
                    d="M2052.8 975.2C2052.8 1002.8 2037.6 1025.2 2015.2 1025.2C1970.4 1025.2 1964.4 994.4 1964.4 975.2C1964.4 954 1978.4 925.6 2015.2 925.6C2037.6 925.6 2052.8 948 2052.8 975.2Z"
                    fill="#372D5B"
                  />
                </g>
                <g id="&#60;Group&#62;_38">
                  <path
                    id="&#60;Path&#62;_37"
                    d="M2084.8 780.4C2160 782 2192.8 829.6 2212.8 881.6C2232.8 934 2209.2 1019.2 2198.4 1040.8C2160.4 1116.8 2047.2 1135.6 2033.2 1134C2019.2 1132.4 1994.8 1094 1979.6 1064.8C1964.4 1035.6 1974.8 1001.6 1970.8 978.8C1967.2 955.6 1958.4 948.8 1955.6 931.6C1940.4 840.8 2009.6 779.2 2084.8 780.4V780.4Z"
                    fill="#F6A397"
                  />
                </g>
                <g id="&#60;Group&#62;_39">
                  <path
                    id="&#60;Path&#62;_38"
                    d="M2010.4 1017.6C2017.6 1019.2 2025.2 1018 2030.8 1013.2C2031.2 1016 2030.8 1019.2 2028.8 1021.6C2026.8 1024 2022.4 1025.6 2018.4 1025.6C2014 1025.6 2009.6 1023.2 2010.4 1017.6V1017.6Z"
                    fill="#D65863"
                  />
                </g>
                <g id="&#60;Group&#62;_40">
                  <path
                    id="&#60;Path&#62;_39"
                    d="M2151.6 931.6C2149.73 919.867 2144.8 910.533 2136.8 903.6C2117.6 895.6 2100.67 890.933 2086 889.6C2071.07 888 2056 885.067 2040.8 880.8C2045.6 892.8 2051.87 901.333 2059.6 906.4C2024.4 904.533 2001.07 898.4 1989.6 888C1992 896.533 1994.27 903.733 1996.4 909.6C1979.07 907.733 1967.6 903.333 1962 896.4C1957.73 911.6 1955.6 923.333 1955.6 931.6C1934.8 903.067 1927.07 877.467 1932.4 854.8C1937.73 832.133 1944 816.533 1951.2 808C1936.27 809.333 1924.67 809.333 1916.4 808C1935.6 791.2 1952.53 777.2 1967.2 766C1981.6 754.8 2010.53 749.067 2054 748.8C2050.53 737.067 2050.67 722 2054.4 703.6C2073.07 731.067 2092.27 748.933 2112 757.2C2141.2 769.6 2137.2 768.4 2168.8 776.4C2200 784.4 2229.6 804.4 2242 821.2C2254.4 838 2268 855.2 2276.4 858C2263.33 856.133 2253.47 852.533 2246.8 847.2C2257.73 871.733 2256 899.867 2241.6 931.6C2220 979.2 2166.4 965.2 2155.6 954.8C2148.67 947.867 2147.33 940.133 2151.6 931.6V931.6Z"
                    fill="#BA6454"
                  />
                </g>
                <g id="&#60;Group&#62;_41">
                  <path
                    id="&#60;Path&#62;_40"
                    d="M2155.6 954.8C2148.67 947.867 2147.33 940.133 2151.6 931.6C2150 920.8 2144.8 912.8 2141.2 908.4C2148.8 860.8 2145.6 811.6 2132 765.6C2140 768.8 2144.8 770.4 2156.4 773.2C2170.8 795.2 2181.6 819.6 2189.2 844.4C2198.8 875.6 2203.2 909.6 2194.4 941.6C2192 949.6 2188.8 957.6 2184.8 964.8C2171.2 964 2160 959.2 2155.6 954.8V954.8Z"
                    fill="#874438"
                  />
                </g>
                <g id="&#60;Group&#62;_42">
                  <path
                    id="&#60;Path&#62;_41"
                    d="M2170.4 914C2173.07 834.8 2153.6 782.533 2112 757.2C2144 753.467 2169.2 767.733 2187.6 800C2215.6 848.4 2213.6 889.6 2212.8 912.8C2212.27 928.267 2198.13 928.667 2170.4 914V914Z"
                    fill="#504984"
                  />
                </g>
                <g id="&#60;Group&#62;_43">
                  <path
                    id="&#60;Path&#62;_42"
                    d="M2189.6 909.6C2238.4 909.6 2256.8 947.2 2256.8 975.2C2256.8 1000.8 2248.8 1041.2 2189.6 1041.2C2160 1041.2 2140 1011.6 2140 975.2C2140 939.2 2160 909.6 2189.6 909.6Z"
                    fill="#504984"
                  />
                </g>
                <g id="&#60;Group&#62;_44">
                  <path
                    id="&#60;Path&#62;_43"
                    d="M2210 1033.6C2235.85 1033.6 2256.8 1007.45 2256.8 975.2C2256.8 942.947 2235.85 916.8 2210 916.8C2184.15 916.8 2163.2 942.947 2163.2 975.2C2163.2 1007.45 2184.15 1033.6 2210 1033.6Z"
                    fill="#372D5B"
                  />
                </g>
                <g id="&#60;Group&#62;_45">
                  <path
                    id="&#60;Path&#62;_44"
                    d="M2216.8 1014.8C2234.69 1014.8 2249.2 997.071 2249.2 975.2C2249.2 953.33 2234.69 935.6 2216.8 935.6C2198.91 935.6 2184.4 953.33 2184.4 975.2C2184.4 997.071 2198.91 1014.8 2216.8 1014.8Z"
                    fill="#504984"
                  />
                </g>
                <g id="&#60;Group&#62;_46">
                  <g id="&#60;Group&#62;_47">
                    <path
                      id="&#60;Path&#62;_45"
                      d="M2060 1074C2051.2 1082.4 2030 1085.6 2019.6 1084C2008.8 1082.8 2002.8 1050 2006 1050.4C2008.8 1050.8 2052 1048.4 2065.2 1041.2C2067.87 1057.47 2066.13 1068.4 2060 1074Z"
                      fill="#D65863"
                    />
                  </g>
                  <g id="&#60;Group&#62;_48">
                    <path
                      id="&#60;Path&#62;_46"
                      d="M2012.4 1064C2012 1064 2012.4 1063.6 2012 1063.6C2010.4 1059.33 2009.2 1054.93 2008.4 1050.4C2017.2 1050.4 2046 1048.4 2060.4 1043.2C2061.2 1045.6 2061.6 1048 2062.4 1050.8C2050.8 1060.8 2031.6 1067.2 2012.4 1064V1064Z"
                      fill="#160F29"
                    />
                  </g>
                </g>
                <g id="&#60;Group&#62;_49">
                  <path
                    id="&#60;Path&#62;_47"
                    d="M2066 973.6C2069.76 973.6 2072.8 968.765 2072.8 962.8C2072.8 956.835 2069.76 952 2066 952C2062.24 952 2059.2 956.835 2059.2 962.8C2059.2 968.765 2062.24 973.6 2066 973.6Z"
                    fill="#3F1751"
                  />
                </g>
                <g id="&#60;Group&#62;_50">
                  <path
                    id="&#60;Path&#62;_48"
                    d="M1989.6 978.4C1992.69 978.4 1995.2 973.565 1995.2 967.6C1995.2 961.635 1992.69 956.8 1989.6 956.8C1986.51 956.8 1984 961.635 1984 967.6C1984 973.565 1986.51 978.4 1989.6 978.4Z"
                    fill="#3F1751"
                  />
                </g>
                <path
                  id="&#60;Path&#62;_49"
                  d="M2096 921.2C2086.4 918.4 2076.4 917.6 2066.8 918.4C2062 918.8 2057.6 920.4 2052.8 921.2C2048 922 2043.6 923.2 2038 924L2037.6 923.2C2041.2 918.8 2045.6 915.6 2050.8 914C2056 912 2061.6 912.4 2066.8 912.4C2071.87 912.667 2076.93 913.467 2082 914.8C2087.2 916.4 2092 918 2096.4 920.4L2096 921.2Z"
                  fill="#3F1751"
                />
                <path
                  id="&#60;Path&#62;_50"
                  d="M1964.4 934C1966.4 932 1968.8 930.4 1971.2 929.2C1973.6 927.867 1976.13 926.934 1978.8 926.4C1981.2 925.6 1984 924.4 1986.8 924.8C1989.6 925.6 1992 926.4 1994.4 928.8V929.6C1993.2 930.4 1991.6 930.8 1990.4 931.6C1989.2 932 1988 932 1986.8 932.4C1984.4 933.2 1982 932 1979.6 932C1977.2 932.267 1974.67 932.533 1972 932.8C1969.6 933.067 1967.2 933.6 1964.8 934.4L1964.4 934Z"
                  fill="#3F1751"
                />
              </g>
              <g id="&#60;Group&#62;_51">
                <g id="&#60;Group&#62;_52">
                  <path
                    id="&#60;Path&#62;_51"
                    d="M2041.2 1713.6C2041.2 1715.2 2038.8 1717.6 2033.2 1717.6C2027.6 1717.6 1872.8 1717.6 1852 1717.6C1831.2 1717.6 1826.4 1709.2 1832.4 1698.8C1838.8 1688.4 1865.2 1665.6 1930.8 1649.6C1996 1633.6 2030.8 1670.8 2041.2 1687.6C2046.53 1695.87 2046.53 1704.53 2041.2 1713.6V1713.6Z"
                    fill="#504984"
                  />
                </g>
                <g id="&#60;Group&#62;_53">
                  <path
                    id="&#60;Path&#62;_52"
                    d="M1830.8 1702C1833.47 1710 1880.8 1711.87 1972.8 1707.6C1963.47 1695.07 1953.6 1688.67 1943.2 1688.4C1928 1687.6 1920.4 1693.2 1899.6 1696.8C1885.73 1699.2 1883.87 1696.4 1894 1688.4C1884.4 1686.27 1877.6 1683.6 1873.6 1680.4C1896.27 1673.73 1912.4 1669.6 1922 1668C1936.4 1665.6 1972.8 1667.2 1977.6 1668C1981.2 1668.4 1998 1658.8 2018.4 1663.6C2029.6 1672 2037.2 1681.2 2041.2 1687.6C2046.53 1695.87 2046.53 1704.53 2041.2 1713.6C2041.2 1715.2 2038.8 1717.6 2033.2 1717.6C2027.6 1717.6 1872.8 1717.6 1852 1717.6C1833.2 1717.6 1827.6 1710.8 1830.8 1702V1702Z"
                    fill="#372D5B"
                  />
                </g>
                <g id="&#60;Group&#62;_54">
                  <path
                    id="&#60;Path&#62;_53"
                    d="M2387.6 1517.6L2521.2 1508C2537.2 1540.8 2549.33 1579.2 2557.6 1623.2C2570 1688.8 2546.8 1722.8 2508 1722.8C2482.4 1722.8 2340.4 1717.6 2082 1707.2C2024.8 1727.6 1982.8 1693.6 1973.2 1686.4C1963.6 1679.2 1943.2 1678 1934.4 1679.6C1926 1681.6 1904.8 1701.2 1886.8 1697.2C1875.07 1694.27 1876.27 1689.33 1890.4 1682.4C1878.67 1682.4 1872 1681.07 1870.4 1678.4C1868.8 1675.47 1870.4 1672.67 1875.2 1670C1867.2 1670.8 1860 1660 1866 1658C1870 1656.93 1882.93 1650.4 1904.8 1638.4C1899.2 1638.67 1893.07 1638 1886.4 1636.4C1876.8 1633.6 1875.2 1622 1882.8 1622C1890 1622 1904.8 1615.6 1923.2 1610C1941.6 1604.4 1977.2 1608.4 1994.8 1615.6C2012.4 1623.2 2068 1643.2 2084 1646.4C2100.4 1649.6 2157.6 1644.4 2223.6 1632.8C2270 1624.27 2336.27 1613.47 2422.4 1600.4L2387.6 1517.6Z"
                    fill="#F6A397"
                  />
                </g>
                <g id="&#60;Group&#62;_55">
                  <path
                    id="&#60;Path&#62;_54"
                    d="M1935.6 1628C1938.8 1627.2 1941.6 1626.4 1943.2 1626.4C1941.07 1626.93 1938.53 1627.47 1935.6 1628Z"
                    fill="#D65863"
                  />
                  <path
                    id="&#60;Path&#62;_55"
                    d="M1904.8 1638.4C1899.2 1638.67 1893.07 1638 1886.4 1636.4C1883.6 1635.6 1881.6 1634 1880.4 1632.4C1891.2 1637.2 1904.4 1634 1911.6 1632.4C1916.4 1631.2 1927.6 1629.2 1935.6 1628C1927.6 1629.6 1916.4 1633.2 1904.8 1638.4Z"
                    fill="#D65863"
                  />
                </g>
                <g id="&#60;Group&#62;_56">
                  <path
                    id="&#60;Path&#62;_56"
                    d="M1906.8 1654C1907.6 1653.73 1908.27 1653.47 1908.8 1653.2C1908.27 1653.47 1907.6 1653.73 1906.8 1654Z"
                    fill="#D65863"
                  />
                  <path
                    id="&#60;Path&#62;_57"
                    d="M1866 1666C1866 1666 1870 1669.6 1878 1666C1884.8 1662.8 1901.2 1656.4 1906.8 1654C1899.6 1657.2 1880.4 1668 1875.2 1670C1871.6 1670.4 1868 1668.4 1866 1666Z"
                    fill="#D65863"
                  />
                </g>
                <path
                  id="&#60;Path&#62;_58"
                  d="M1886 1678.8C1892 1676.8 1904.4 1669.6 1912 1666C1917.07 1663.6 1922.8 1662.27 1929.2 1662C1927.33 1662 1923.87 1663.33 1918.8 1666C1913.73 1668.4 1904.27 1673.87 1890.4 1682.4C1882.93 1682.4 1877.33 1681.73 1873.6 1680.4C1873.6 1680.4 1880 1680.4 1886 1678.8V1678.8Z"
                  fill="#D65863"
                />
                <g id="&#60;Group&#62;_57">
                  <path
                    id="&#60;Path&#62;_59"
                    d="M1996.4 1687.2C2007.2 1694 2039.6 1702.8 2058.8 1699.2C2071.87 1696.8 2079.73 1690.27 2082.4 1679.6L2088 1696.8C2267.2 1701.87 2374.93 1703.6 2411.2 1702C2465.2 1699.6 2549.6 1698.4 2560.8 1666C2557.6 1703.6 2537.2 1722.8 2508 1722.8C2482.4 1722.8 2340.4 1717.6 2082 1707.2C2024.8 1727.6 1982.8 1693.6 1973.2 1686.4C1965.2 1680.4 1950 1678.4 1940 1679.2C1954.8 1674 1985.2 1680.4 1996.4 1687.2V1687.2Z"
                    fill="#D65863"
                  />
                </g>
                <g id="&#60;Group&#62;_58">
                  <path
                    id="&#60;Path&#62;_60"
                    d="M2411.2 1574L2475.2 1561.2L2482.8 1592.4C2475.87 1593.2 2455.73 1595.87 2422.4 1600.4L2411.2 1574Z"
                    fill="#D65863"
                  />
                </g>
                <g id="&#60;Group&#62;_59">
                  <path
                    id="&#60;Path&#62;_61"
                    d="M2556.8 1512.4C2558.8 1517.6 2557.6 1522.8 2553.6 1526.8C2530.4 1549.6 2499.6 1580.8 2468 1585.2C2433.6 1590.8 2376 1592.8 2372.8 1585.2C2369.6 1578 2358.4 1485.2 2337.6 1438C2316.4 1391.2 2302.8 1367.2 2297.6 1340C2292.4 1312.8 2339.6 1249.2 2360.4 1244C2381.2 1238.8 2429.2 1235.6 2449.2 1253.2C2469.2 1270.8 2483.6 1338.8 2506.8 1390C2525.2 1431.2 2548.4 1491.2 2556.8 1512.4V1512.4Z"
                    fill="#5470D3"
                  />
                </g>
                <g id="&#60;Group&#62;_60">
                  <path
                    id="&#60;Path&#62;_62"
                    d="M2427.6 1242.8C2421.2 1242 2414.8 1241.6 2408.4 1241.6C2402 1241.33 2395.6 1241.33 2389.2 1241.6C2382.8 1241.87 2376.53 1242.53 2370.4 1243.6C2367.2 1244.4 2364 1245.2 2360.8 1245.6C2358 1246.8 2355.2 1247.6 2352.4 1249.2C2349.6 1250.4 2347.2 1252.4 2344.8 1254C2342.4 1256 2339.6 1258 2337.6 1260.4C2333.2 1264.8 2329.2 1269.6 2325.2 1274.8C2321.47 1279.87 2318 1285.2 2314.8 1290.8C2311.2 1296 2308.4 1302 2305.2 1307.6C2309.6 1295.2 2315.6 1284 2322.8 1272.8C2326.4 1267.6 2330.4 1262.4 2334.8 1257.6C2337.2 1255.2 2339.6 1253.2 2342.4 1251.2C2344.8 1249.2 2347.6 1247.2 2350.8 1245.6C2353.6 1244 2356.8 1243.2 2360 1242C2363.2 1241.2 2366.4 1240.4 2369.6 1240C2376 1239.2 2382.4 1238.4 2389.2 1238.4C2402 1238.4 2414.8 1240 2427.6 1242.8V1242.8Z"
                    fill="#314EC6"
                  />
                </g>
                <g id="&#60;Group&#62;_61">
                  <path
                    id="&#60;Path&#62;_63"
                    d="M2530.8 1531.6C2530.4 1515.2 2495.2 1362 2464 1276.8C2477.2 1306 2489.6 1352.4 2506.8 1390C2525.2 1431.2 2548.4 1491.2 2556.8 1512.4C2558.8 1517.6 2557.6 1522.8 2553.6 1526.8C2544.8 1535.2 2534.8 1545.2 2524 1554.4C2524.8 1553.6 2530.8 1547.2 2530.8 1531.6V1531.6Z"
                    fill="#314EC6"
                  />
                </g>
              </g>
            </g>
            <g id="&#60;Group&#62;_62">
              <g id="&#60;Group&#62;_63">
                <g id="&#60;Group&#62;_64">
                  <path
                    id="&#60;Path&#62;_64"
                    d="M850.4 1359.6C852.4 1345.2 865.6 1335.6 879.6 1338.4C885.6 1339.6 891.2 1342.4 895.2 1346.4C908.8 1360 904.4 1383.6 916 1398.4C918.8 1402 922.8 1405.2 925.2 1408.8C935.2 1423.2 926.4 1442.8 929.6 1459.6C933.6 1480.4 955.2 1494.4 959.2 1515.2C964 1537.2 948 1557.6 933.2 1574.4C931.6 1576 930 1578 927.6 1578.8C923.2 1580.8 918.4 1578 915.2 1574.4C902 1560.4 908 1538 901.6 1520C894.8 1499.6 872.8 1486.8 867.2 1466C863.6 1454 866 1441.2 866.4 1428.8C867.2 1410 862.4 1390.8 853.6 1374.4C850.8 1369.6 850 1364.4 850.4 1359.6V1359.6Z"
                    fill="#BA5E5B"
                  />
                </g>
                <g id="&#60;Group&#62;_65">
                  <path
                    id="&#60;Path&#62;_65"
                    d="M931.2 1589.2C930.933 1578.27 930.266 1567.33 929.2 1556.4C928.4 1545.2 927.2 1534.4 925.2 1523.6C923.2 1513.2 920.8 1502.4 916.4 1492.8C908.4 1472 902 1450.8 895.2 1430L885.6 1398C883.6 1387.2 881.2 1376.4 879.6 1365.6C883.066 1376 886.8 1386.4 890.8 1396.8L900.4 1428.4C906.8 1449.2 913.2 1470.4 921.2 1490.8C926 1500.8 928.4 1512 930.4 1522.8C932.4 1534 933.6 1544.8 934.8 1556C935.6 1566.8 936 1578 936.8 1588.8C936.4 1600 936 1611.2 934.8 1622L931.2 1589.2Z"
                    fill="#FFA6A4"
                  />
                  <path
                    id="&#60;Path&#62;_66"
                    d="M876.8 1398.4C880 1399.2 882.8 1400.8 885.2 1402.8C886.4 1403.6 887.6 1404.8 888.4 1406C889.467 1407.33 890.8 1408.27 892.4 1408.8L891.2 1409.2C892 1407.2 892.4 1405.6 893.2 1403.6C893.733 1401.73 894.4 1400 895.2 1398.4C896.8 1394.93 899.066 1391.73 902 1388.8V1389.2C901.466 1392.93 900.4 1396.53 898.8 1400C897.733 1401.6 896.666 1403.33 895.6 1405.2C894.8 1406.8 893.6 1408 892.4 1409.6L892 1410.4L891.6 1410C890.266 1408.93 888.933 1408 887.6 1407.2C886.4 1406.8 884.8 1406 883.6 1405.2C880.8 1403.2 878.4 1401.2 876.8 1398.4V1398.4Z"
                    fill="#FFA6A4"
                  />
                  <path
                    id="&#60;Path&#62;_67"
                    d="M879.6 1436C882 1436.4 884.4 1437.6 886.8 1438.4C888.933 1439.73 891.066 1441.07 893.2 1442.4C895.2 1444 897.2 1445.6 899.2 1447.2C901.2 1448.4 903.6 1449.6 905.6 1450.8L904.4 1451.2C906 1446.4 908.4 1442 910.8 1437.6C913.6 1433.6 916.4 1429.6 919.6 1425.6L920 1426C918.4 1430.8 916.4 1435.2 914 1439.6C911.6 1444 909.2 1448 906 1452L905.2 1452.8L904.8 1452.4C902.8 1450.8 900.8 1449.2 898.4 1448C896.4 1446.8 894 1446 891.6 1444.8C889.6 1443.6 887.2 1442.4 885.2 1440.8C883.2 1439.6 881.2 1438 879.6 1436.4V1436Z"
                    fill="#FFA6A4"
                  />
                  <path
                    id="&#60;Path&#62;_68"
                    d="M893.2 1485.6C896 1486 898.8 1486.8 901.2 1487.6C903.866 1488.67 906.267 1490.13 908.4 1492C910.533 1493.87 912.933 1495.2 915.6 1496C918.4 1496.8 921.2 1497.6 923.6 1499.2L920.8 1500C921.866 1496.8 923.333 1493.87 925.2 1491.2C927.2 1488.4 929.2 1486 931.6 1483.6H932C931.6 1487.2 930.4 1490.4 929.2 1493.2C927.866 1496.13 926.267 1499.07 924.4 1502L923.2 1503.6L921.2 1502.8C919.2 1501.2 916.8 1499.2 914.8 1497.6C912.666 1496 910.133 1494.8 907.2 1494C904.533 1493.47 902 1492.53 899.6 1491.2C897.2 1489.6 894.8 1488 892.8 1486L893.2 1485.6Z"
                    fill="#FFA6A4"
                  />
                </g>
              </g>
              <g id="&#60;Group&#62;_66">
                <g id="&#60;Group&#62;_67">
                  <path
                    id="&#60;Path&#62;_69"
                    d="M939.2 1620C921.2 1601.6 914.4 1572.4 924 1548.4C931.6 1529.6 947.6 1512 943.2 1492.8C941.6 1485.6 936.8 1479.2 935.2 1472C931.2 1453.6 946 1437.6 953.2 1420.4C962.8 1396 956.8 1368.8 952.8 1342.8C948.8 1317.2 948 1287.6 965.6 1268.4C969.6 1263.2 975.2 1259.2 981.6 1257.6C990.4 1255.6 999.6 1258.8 1008.4 1262C1033.2 1272.4 1058.8 1294 1054.8 1320.4C1052 1340 1033.6 1354.4 1030 1373.6C1026 1392 1036.4 1410 1044.8 1426.8C1053.6 1443.6 1060.4 1464 1050.8 1480.4C1041.6 1496 1021.6 1500.4 1005.2 1509.2C971.6 1526.8 981.6 1567.6 991.2 1604C993.6 1612.4 961.2 1622.8 952.4 1624C947.6 1624.8 942.8 1623.2 939.2 1620V1620Z"
                    fill="#E67471"
                  />
                </g>
                <g id="&#60;Group&#62;_68">
                  <path
                    id="&#60;Path&#62;_70"
                    d="M950.399 1581.2C950.799 1577.6 950.799 1574 951.199 1570.4C951.599 1566.8 952.399 1563.2 952.799 1559.6C953.866 1552.4 955.066 1545.2 956.399 1538C959.199 1524 962.799 1509.6 967.599 1495.6C969.999 1488.8 973.199 1482 977.599 1475.6C979.599 1472.4 981.999 1469.2 985.599 1466.8C988.533 1464.93 990.933 1462.8 992.799 1460.4C996.799 1455.2 997.999 1448.8 997.599 1442C997.599 1435.2 996.399 1428 994.799 1421.2C991.599 1407.2 987.199 1393.2 984.799 1378.8C982.399 1364.4 981.999 1349.2 985.999 1334.4C986.799 1330.8 987.999 1327.2 989.999 1324C991.866 1320.8 993.866 1317.73 995.999 1314.8C1000.4 1308.8 1005.6 1303.6 1011.6 1299.2C1006.8 1304.4 1002.4 1310.4 999.199 1316.8C997.599 1320 996.266 1323.2 995.199 1326.4C993.999 1329.6 993.199 1333.2 991.999 1336.4C988.399 1349.6 988.799 1364 991.199 1377.6C993.599 1391.6 997.999 1405.6 1001.2 1419.6C1002.8 1426.8 1004 1434 1004.4 1441.6C1004.4 1449.2 1003.2 1457.6 997.999 1464.4C995.599 1467.6 992.399 1470.4 989.199 1472.4C986.799 1474 984.799 1476.4 982.799 1479.2C979.199 1484.8 975.999 1491.2 973.599 1498C968.799 1511.2 965.599 1525.2 962.799 1539.2C961.466 1546.4 960.266 1553.47 959.199 1560.4C958.799 1564 958.399 1567.6 957.999 1571.2C957.199 1574.8 956.799 1578.4 955.999 1581.6C953.599 1596 951.599 1610.4 949.599 1624.4C949.199 1610 949.599 1595.6 950.399 1581.2V1581.2Z"
                    fill="#FFA6A4"
                  />
                  <path
                    id="&#60;Path&#62;_71"
                    d="M1006.8 1342.4C1005.47 1344.53 1004.13 1346.53 1002.8 1348.4C1001.2 1350 999.599 1352 997.599 1353.6C995.599 1354.8 993.199 1356.4 991.599 1358C989.999 1360 988.799 1362 987.599 1364L986.799 1365.6L985.199 1364C983.599 1362.4 982.399 1360.8 981.199 1359.2C979.999 1357.6 978.799 1356 977.599 1354C976.799 1352.13 975.999 1350.27 975.199 1348.4C974.399 1346.4 973.599 1344.4 973.999 1342H974.399C975.199 1343.6 976.799 1345.2 977.999 1346.8C979.199 1348.4 980.399 1350 981.599 1351.6C982.666 1353.2 983.733 1354.93 984.799 1356.8C985.599 1358.4 986.799 1360.4 987.599 1362H985.199C987.199 1360.4 989.199 1358.8 990.799 1357.2C992.399 1355.6 993.599 1353.2 995.199 1351.2C996.399 1349.2 998.399 1347.2 999.999 1346C1002 1344.4 1004 1342.8 1006.8 1342V1342.4Z"
                    fill="#FFA6A4"
                  />
                  <path
                    id="&#60;Path&#62;_72"
                    d="M975.599 1396.4C977.733 1398 979.733 1399.73 981.599 1401.6C983.599 1403.2 985.199 1404.8 986.799 1406.8C988.399 1408.8 989.999 1411.2 991.599 1413.2C992.933 1415.33 994.666 1417.2 996.799 1418.8H995.599C997.199 1416.8 998.399 1414 1000.4 1411.6C1002 1409.2 1003.6 1406.8 1005.6 1404.4C1007.47 1402 1009.47 1399.87 1011.6 1398C1014 1396 1016 1393.6 1018.8 1392.4L1019.2 1392.8C1017.6 1395.2 1016 1397.6 1014.4 1400C1012.8 1402.67 1011.07 1405.07 1009.2 1407.2C1007.2 1409.6 1005.2 1411.6 1003.2 1414C1001.2 1416 999.199 1418 996.799 1420L995.999 1420.8L995.599 1420C993.999 1417.87 992.133 1416 989.999 1414.4C987.999 1412.8 985.999 1411.2 983.999 1409.6C982.399 1407.73 980.799 1405.73 979.199 1403.6C977.599 1401.47 976.266 1399.2 975.199 1396.8L975.599 1396.4Z"
                    fill="#FFA6A4"
                  />
                  <path
                    id="&#60;Path&#62;_73"
                    d="M1033.2 1439.2C1032 1443.6 1030 1447.2 1027.2 1450.4C1024.4 1453.6 1021.2 1456.8 1017.2 1458.8C1013.2 1460.8 1009.2 1462 1005.6 1463.6C1001.6 1464.8 997.599 1466.4 993.199 1466.8L991.999 1467.2L991.199 1465.6C989.999 1462.8 989.199 1459.6 987.599 1456.8L983.599 1447.6C982.399 1444.8 980.799 1442 979.199 1439.2C977.599 1436.4 975.599 1433.6 973.199 1431.6V1431.2C976.399 1432.4 979.199 1434.8 981.599 1437.2C983.999 1439.87 985.999 1442.67 987.599 1445.6C989.199 1448.53 990.666 1451.6 991.999 1454.8C992.799 1458.4 993.999 1461.2 994.799 1464.8L992.799 1463.6C996.799 1463.6 1001.2 1463.6 1004.8 1462C1008.8 1461.2 1012.4 1458.8 1015.6 1456C1018.4 1453.2 1021.6 1450.8 1024.4 1448C1027.33 1445.33 1030.13 1442.4 1032.8 1439.2H1033.2Z"
                    fill="#FFA6A4"
                  />
                  <path
                    id="&#60;Path&#62;_74"
                    d="M955.599 1471.6C955.599 1478.4 957.199 1484.4 958.799 1490.4C959.999 1493.2 960.799 1496.4 961.999 1499.6C963.199 1502.4 965.599 1505.2 967.599 1507.6L966.399 1507.2C969.599 1504.4 972.799 1501.6 975.999 1499.2C979.599 1497.2 983.199 1494.8 987.199 1493.2C994.799 1490 1003.2 1488 1011.6 1488.4V1488.8C1007.6 1490.4 1003.6 1491.6 999.999 1492.8C996.266 1494.4 992.533 1496 988.799 1497.6C985.199 1499.2 981.599 1500.8 977.999 1502.8C974.399 1504.4 970.799 1506.4 967.599 1508.4L966.799 1509.2L966.399 1508.4L960.399 1500.4C958.399 1497.6 956.399 1494.8 955.199 1491.6C953.999 1488.4 953.599 1484.8 953.199 1481.6C953.199 1478 953.599 1474.8 955.199 1471.6H955.599Z"
                    fill="#FFA6A4"
                  />
                </g>
              </g>
              <g id="&#60;Group&#62;_69">
                <g id="&#60;Group&#62;_70">
                  <g id="&#60;Group&#62;_71">
                    <path
                      id="&#60;Path&#62;_75"
                      d="M876.8 1700.4L862 1586.8C860.4 1574.4 868.8 1563.2 879.2 1563.2H1000.8C1011.2 1563.2 1019.6 1574.4 1018 1586.8L1003.2 1700.4C1002 1710.4 994.8 1717.6 986 1717.6H894C885.2 1717.6 878 1710.4 876.8 1700.4V1700.4Z"
                      fill="#504984"
                    />
                  </g>
                </g>
                <g id="&#60;Group&#62;_72">
                  <path
                    id="&#60;Path&#62;_76"
                    d="M879.2 1563.2H941.2C930.8 1563.2 922.8 1574.4 924.4 1586.8L938.8 1700.4C940 1710.4 947.6 1717.6 956 1717.6H894C885.2 1717.6 878 1710.4 876.8 1700.4L862 1586.8C860.4 1574.4 868.8 1563.2 879.2 1563.2V1563.2Z"
                    fill="#372D5B"
                  />
                </g>
              </g>
            </g>
            <g id="&#60;Group&#62;_73">
              <g id="&#60;Group&#62;_74">
                <g id="&#60;Group&#62;_75">
                  <path
                    id="&#60;Path&#62;_77"
                    d="M1720 1717.6H1334.4C1330.4 1717.6 1327.2 1714.4 1327.2 1710.4V1693.6C1327.2 1689.6 1330.4 1686.4 1334.4 1686.4H1720V1717.6Z"
                    fill="#282142"
                  />
                </g>
                <g id="&#60;Group&#62;_76">
                  <path
                    id="&#60;Path&#62;_78"
                    d="M1819.2 1708V1714.8C1819.2 1716.4 1818 1717.6 1816.4 1717.6H1719.6C1716.4 1717.6 1714 1715.2 1714 1712V1692C1714 1688.8 1716.8 1686 1720 1686.4L1816.8 1705.2C1818.4 1705.6 1819.2 1706.4 1819.2 1708V1708Z"
                    fill="#504984"
                  />
                </g>
              </g>
              <g id="&#60;Group&#62;_77">
                <g id="&#60;Group&#62;_78">
                  <g id="&#60;Group&#62;_79">
                    <path
                      id="&#60;Path&#62;_79"
                      d="M1046 1030.8H1766.8C1781.6 1030.8 1794.4 1040 1799.6 1053.6L1957.2 1491.6C1963.6 1514 1947.2 1531.2 1920.4 1534C1916.4 1534.4 1897.2 1525.6 1866.8 1511.2H1222.4C1207.6 1511.2 1194.8 1502.4 1189.6 1488.4L1047.2 1092.8C1031.2 1084.53 1018.67 1078 1009.6 1073.2C1003.47 1044.93 1015.6 1030.8 1046 1030.8V1030.8Z"
                      fill="#504984"
                    />
                  </g>
                  <g id="&#60;Group&#62;_80">
                    <path
                      id="&#60;Path&#62;_80"
                      d="M1776.8 1076.4L1934.4 1514C1938 1523.6 1930.8 1534 1920.4 1534H1199.6C1185.2 1534 1172 1524.8 1167.2 1511.2L1009.6 1073.2C1006 1063.6 1013.2 1053.6 1023.2 1053.6H1744.4C1758.8 1053.6 1772 1062.4 1776.8 1076.4Z"
                      fill="#372D5B"
                    />
                  </g>
                  <g id="&#60;Group&#62;_81">
                    <path
                      id="&#60;Path&#62;_81"
                      d="M1428.4 1534L1549.6 1415.2L1581.6 1534H1428.4Z"
                      fill="#282142"
                    />
                  </g>
                  <g id="&#60;Group&#62;_82">
                    <path
                      id="&#60;Path&#62;_82"
                      d="M1430 1179.6C1445.2 1179.6 1458.4 1192.4 1460.8 1207.2C1463.6 1222 1452 1234.8 1437.2 1234.8C1422 1234.8 1409.2 1226.8 1406 1207.2C1403.6 1192.4 1414.8 1179.6 1430 1179.6Z"
                      fill="#504984"
                    />
                  </g>
                  <g id="&#60;Group&#62;_83">
                    <g id="&#60;Group&#62;_84">
                      <path
                        id="&#60;Path&#62;_83"
                        d="M1744.8 1461.2L1735.6 1435.2H1747.2L1756.4 1461.2H1744.8Z"
                        fill="#282142"
                      />
                    </g>
                    <g id="&#60;Group&#62;_85">
                      <path
                        id="&#60;Path&#62;_84"
                        d="M1720.4 1461.2L1711.2 1435.2H1722.8L1732 1461.2H1720.4Z"
                        fill="#282142"
                      />
                    </g>
                    <g id="&#60;Group&#62;_86">
                      <path
                        id="&#60;Path&#62;_85"
                        d="M1695.6 1461.2L1686.4 1435.2H1698L1707.6 1461.2H1695.6Z"
                        fill="#282142"
                      />
                    </g>
                    <g id="&#60;Group&#62;_87">
                      <path
                        id="&#60;Path&#62;_86"
                        d="M1671.2 1461.2L1662 1435.2H1673.6L1682.8 1461.2H1671.2Z"
                        fill="#282142"
                      />
                    </g>
                  </g>
                  <g id="&#60;Group&#62;_88">
                    <g id="&#60;Group&#62;_89">
                      <path
                        id="&#60;Path&#62;_87"
                        d="M1322.8 1461.2L1313.6 1435.2H1325.2L1334.4 1461.2H1322.8Z"
                        fill="#282142"
                      />
                    </g>
                    <g id="&#60;Group&#62;_90">
                      <path
                        id="&#60;Path&#62;_88"
                        d="M1298 1461.2L1288.8 1435.2H1300.4L1309.6 1461.2H1298Z"
                        fill="#282142"
                      />
                    </g>
                    <g id="&#60;Group&#62;_91">
                      <path
                        id="&#60;Path&#62;_89"
                        d="M1273.6 1461.2L1264.4 1435.2H1276L1285.2 1461.2H1273.6Z"
                        fill="#282142"
                      />
                    </g>
                    <g id="&#60;Group&#62;_92">
                      <path
                        id="&#60;Path&#62;_90"
                        d="M1249.2 1461.2L1239.6 1435.2H1251.6L1260.8 1461.2H1249.2Z"
                        fill="#282142"
                      />
                    </g>
                  </g>
                </g>
                <g id="&#60;Group&#62;_93">
                  <g id="&#60;Group&#62;_94">
                    <path
                      id="&#60;Path&#62;_91"
                      d="M1338 1675.2C1342.8 1661.6 1374 1562 1431.6 1376.4H1542.4L1549.6 1415.2C1500 1574.93 1472.8 1661.6 1468 1675.2C1467.47 1676.8 1467.07 1678.4 1466.8 1680C1460 1700 1477.2 1700 1496.8 1700C1509.6 1700 1574.67 1700 1692 1700V1717.6H1496.8H1366.4C1327.6 1717.6 1330.8 1695.6 1338 1675.2V1675.2Z"
                      fill="#372D5B"
                    />
                  </g>
                  <g id="&#60;Group&#62;_95">
                    <path
                      id="&#60;Path&#62;_92"
                      d="M1542.4 1376.4C1484.8 1562 1453.6 1661.6 1448.8 1675.2C1441.6 1695.6 1438 1717.6 1477.2 1717.6H1347.2C1308 1717.6 1311.6 1695.6 1318.8 1675.2C1323.6 1661.6 1354.67 1562 1412 1376.4H1542.4Z"
                      fill="#504984"
                    />
                  </g>
                  <g id="&#60;Group&#62;_96">
                    <path
                      id="&#60;Path&#62;_93"
                      d="M1489.2 1377.2C1480.8 1377.6 1472.4 1377.6 1463.6 1378C1455.2 1378 1446.4 1378.4 1438 1378.4H1412L1414 1377.2C1410.53 1388.4 1407.07 1399.6 1403.6 1410.8L1392.8 1444.4C1385.6 1466.8 1378 1489.2 1370.4 1511.2C1376.4 1488.4 1382.8 1466 1389.6 1443.6L1399.6 1409.6C1403.07 1398.4 1406.67 1387.2 1410.4 1376L1410.8 1374.4H1412L1438 1374.8C1446.4 1374.8 1455.2 1374.8 1463.6 1375.2C1472.4 1375.2 1480.8 1375.2 1489.2 1375.6C1498 1376 1506.4 1376 1515.2 1376.4C1506.4 1376.8 1498 1377.2 1489.2 1377.2V1377.2Z"
                      fill="#7F74D1"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="&#60;Group&#62;_97">
              <g id="&#60;Group&#62;_98">
                <path
                  id="&#60;Path&#62;_94"
                  d="M726.4 1751.6C726.4 1732.8 742 1717.6 760.8 1717.6H2670C2688.8 1717.6 2704.4 1732.8 2704.4 1751.6C2704.4 1770.8 2688.8 1786 2670 1786H760.8C742 1786 726.4 1770.8 726.4 1751.6Z"
                  fill="#8E5058"
                />
              </g>
              <g id="&#60;Group&#62;_99">
                <path
                  id="&#60;Path&#62;_95"
                  d="M726.4 1751.6H2704.4C2704.4 1770.8 2688.8 1786 2670 1786H760.8C742 1786 726.4 1770.8 726.4 1751.6Z"
                  fill="#64364A"
                />
              </g>
            </g>
            <g id="&#60;Group&#62;_100">
              <g id="&#60;Group&#62;_101">
                <path
                  id="&#60;Path&#62;_96"
                  d="M876.8 923.6C1035.64 923.6 1164.4 794.837 1164.4 636C1164.4 477.163 1035.64 348.4 876.8 348.4C717.963 348.4 589.2 477.163 589.2 636C589.2 794.837 717.963 923.6 876.8 923.6Z"
                  fill="#5470D3"
                />
              </g>
              <g id="&#60;Clip Group&#62;">
                <g id="&#60;Group&#62;_102">
                  <g id="&#60;Group&#62;_103">
                    <g id="&#60;Group&#62;_104">
                      <path
                        id="&#60;Path&#62;_97"
                        d="M1280.8 865.6C1286.4 892.8 1260.8 917.2 1252.8 922C1245.2 926.4 1191.6 947.6 1159.6 948.4C1127.6 949.2 1085.2 881.6 1102.8 874C1120 866.4 1238 858 1250.8 858C1259.33 858 1269.33 860.534 1280.8 865.6Z"
                        fill="#FFC5C7"
                      />
                      <path
                        id="&#60;Path&#62;_98"
                        d="M1196.8 850.8C1182.8 933.2 1149.6 997.2 1139.2 998.4C1128.8 999.6 1029.2 965.2 1002 952.8C974.4 940 1010.4 747.2 1027.2 750.4C1044.4 754 1082 790.8 1112.8 808.8C1133.33 820.534 1161.33 834.534 1196.8 850.8Z"
                        fill="#160F29"
                      />
                      <path
                        id="&#60;Path&#62;_99"
                        d="M1032.8 885.2C1042.4 901.6 1121.6 943.2 1165.2 958.8C1154.4 982.8 1144.4 998 1139.2 998.4C1130.4 999.2 1056 974.4 1017.6 959.2C1019.73 938.934 1024.8 914.267 1032.8 885.2Z"
                        fill="#DCEDFF"
                      />
                      <path
                        id="&#60;Path&#62;_100"
                        d="M1280.8 861.6C1281.6 894.4 1252.4 898.8 1217.2 890.8C1182 882.4 1124.4 803.2 1098.8 767.2C1073.2 731.6 1025.6 667.6 1015.6 662C1006 656 983.2 653.2 964 620.8C944.4 588.8 970 521.2 979.2 513.2C988.4 505.2 1066.8 532 1076 545.6C1084.8 559.2 1069.6 631.6 1072 640.8C1074.8 650 1141.6 724.4 1170.8 742.8C1200 761.2 1280.4 838 1280.8 861.6V861.6Z"
                        fill="#FFC5C7"
                      />
                      <path
                        id="&#60;Path&#62;_101"
                        d="M1019.2 567.2C1010 562.8 1008 542 1013.6 545.6C1019.6 549.2 1029.6 549.2 1043.2 548.8C1052.53 548.8 1059.07 548.4 1062.8 547.6C1063.6 553.467 1064 558.933 1064 564C1040.27 569.333 1025.33 570.4 1019.2 567.2Z"
                        fill="#FE8A8B"
                      />
                      <path
                        id="&#60;Path&#62;_102"
                        d="M958.4 572C962.133 565.067 965.333 558.933 968 553.6L1022 605.6C1031.87 597.067 1042.8 588.933 1054.8 581.2L1059.6 589.6C1050.8 604.4 1043.2 635.6 1043.2 643.2C1037.6 649.333 1033.33 652.667 1030.4 653.2C1068.8 714 1100.4 759.467 1125.2 789.6C1162 834 1187.2 872.8 1204.8 885.2C1169.6 864.8 1121.6 799.2 1098.8 767.2C1073.2 731.6 1025.6 667.6 1015.6 662C1006 656 983.2 653.2 964 620.8C956.4 608.4 955.6 590 958.4 572V572Z"
                        fill="#FE8A8B"
                      />
                      <path
                        id="&#60;Path&#62;_103"
                        d="M1070.4 577.2C1070 582.4 1016.4 628.8 1010.8 634.4C1010.4 634.8 1006 635.2 1004.8 634.4C990.8 621.6 928.4 508.8 927.2 504.8C926.4 500.8 967.6 462 980.4 451.6C982 450 989.2 450.8 990 452.8C1002.8 471.6 1071.6 570.4 1070.4 577.2Z"
                        fill="#0C0835"
                      />
                      <path
                        id="&#60;Path&#62;_104"
                        d="M1064.8 577.2C1064 582.4 1010.4 628.8 1004.8 634.4C999.2 639.6 922.8 509.2 921.6 504.8C920.4 500.8 966.4 462.4 979.2 452C980.8 450.8 983.2 450.8 984.4 452.8C997.2 471.6 1065.6 570.4 1064.8 577.2Z"
                        fill="#252384"
                      />
                      <path
                        id="&#60;Path&#62;_105"
                        d="M1055.6 569.6C1055.2 574.4 1005.2 618 999.6 622.8C994.4 628 922.8 506 921.6 501.6C920.8 498 964 462.4 975.6 452.8C977.2 451.2 979.6 451.6 980.4 453.2C992.4 470.8 1056.4 563.2 1055.6 569.6Z"
                        fill="#57AFF7"
                      />
                      <path
                        id="&#60;Path&#62;_106"
                        d="M1023.6 506C1015.2 500.8 1005.2 484 1004.4 478C1003.2 472.4 1004 467.2 1008.4 466C1012.8 465.2 1026.4 482.8 1033.2 488.4C1040.4 493.6 1048.4 504.8 1045.2 512C1041.6 519.6 1026.4 516.8 1024.8 515.6C1023.73 514.8 1023.33 511.6 1023.6 506V506Z"
                        fill="#FFC5C7"
                      />
                      <path
                        id="&#60;Path&#62;_107"
                        d="M1005.6 467.6C1003.2 474 1020.4 500.4 1035.2 507.6L1029.6 514C1028 513.733 1026.13 513.333 1024 512.8C1023.73 510.933 1023.6 508.667 1023.6 506C1015.2 500.8 1005.2 484 1004.4 478C1003.6 474 1003.6 470 1005.6 467.6V467.6Z"
                        fill="#FE8A8B"
                      />
                      <path
                        id="&#60;Path&#62;_108"
                        d="M1042.8 532C1028.8 524.4 1017.6 525.2 1009.6 522.4C1002 519.2 1000.4 507.2 1005.6 505.2C1011.2 503.2 1043.2 508.4 1051.6 512C1057.2 514.4 1061.33 521.334 1064 532.8L1042.8 532Z"
                        fill="#FFC5C7"
                      />
                      <path
                        id="&#60;Path&#62;_109"
                        d="M1036.8 523.2C1042.13 524.267 1045.73 524.267 1047.6 523.2C1047.2 526.4 1050.4 528 1053.6 532.4L1042.8 532C1028.8 524.4 1017.6 525.2 1009.6 522.4C1006 520.8 1004 517.6 1003.2 514C1006.4 520.8 1028.8 521.6 1036.8 523.2V523.2Z"
                        fill="#FE8A8B"
                      />
                      <path
                        id="&#60;Path&#62;_110"
                        d="M1076 545.6C1068.4 556 1030.8 551.6 1023.2 550.4C1016 549.2 1011.2 544.4 1012 539.6C1013.2 530 1062.8 531.6 1066.8 533.2C1069.47 534.267 1072.53 538.4 1076 545.6Z"
                        fill="#FFC5C7"
                      />
                      <path
                        id="&#60;Path&#62;_111"
                        d="M1012 542C1017.2 547.2 1029.6 550 1042.8 551.2C1054.4 552 1061.2 551.6 1062.8 551.6C1048.8 553.6 1028.8 551.2 1023.2 550.4C1017.2 549.6 1012.8 546 1012 542V542Z"
                        fill="#FE8A8B"
                      />
                    </g>
                    <g id="&#60;Group&#62;_105">
                      <path
                        id="&#60;Path&#62;_112"
                        d="M900.799 693.2C980.399 733.6 993.199 732 1027.2 750.4C1061.6 768.8 1051.2 961.6 1049.2 991.2C1046.8 1020.8 1038.8 1138.8 1043.2 1170.8C1048 1202.8 1065.6 1321.6 1073.2 1340C1081.2 1358.4 1096 1389.2 1072.8 1407.6C1050 1425.6 1001.2 1438.4 895.199 1431.6C789.599 1424.4 675.199 1359.6 670.399 1350.4C665.199 1341.2 720.799 1173.2 723.199 1141.2C725.599 1109.2 731.999 1085.2 735.199 1070.4C738.799 1055.6 704.799 986 699.599 941.2C694.399 896.8 645.999 773.6 654.799 751.2C663.999 728.8 748.399 703.6 805.999 675.2C844.133 656 875.733 662 900.799 693.2V693.2Z"
                        fill="#160F29"
                      />
                      <path
                        id="&#60;Path&#62;_113"
                        d="M910.799 866C864.799 849.6 821.999 825.2 782.799 796.4C817.999 823.2 861.599 842 904.399 852.4C939.999 824.8 966.399 786.4 979.599 743.2C972.799 792.8 949.599 834.4 910.799 866V866Z"
                        fill="#DCEDFF"
                      />
                      <path
                        id="&#60;Path&#62;_114"
                        d="M894.399 828.8C830.399 807.2 774.799 761.6 740.799 703.6C762.799 694.4 785.999 684.8 805.999 675.2C844.133 656 875.733 662 900.799 693.2C928.799 707.2 947.999 716.4 963.599 722.8C952.399 764.4 927.999 802.4 894.399 828.8V828.8Z"
                        fill="#FFC5C7"
                      />
                      <path
                        id="&#60;Path&#62;_115"
                        d="M670.399 1350.4C665.199 1341.2 720.799 1173.2 723.199 1141.2C725.599 1109.2 731.999 1085.2 735.199 1070.4C738.799 1055.6 704.799 986 699.599 941.2C698.799 933.6 696.399 923.2 693.599 912L729.199 885.2C749.999 943.6 763.199 1046.4 783.199 1071.6C796.266 1088.4 819.333 1100.27 852.399 1107.2C819.866 1114.67 796.533 1116.93 782.399 1114C767.199 1174.27 759.466 1219.2 759.199 1248.8C758.799 1293.2 763.199 1360.8 763.599 1370C763.999 1373.2 763.199 1385.2 761.999 1398.8C710.799 1378.4 673.199 1355.6 670.399 1350.4V1350.4Z"
                        fill="#DCEDFF"
                      />
                      <path
                        id="&#60;Path&#62;_116"
                        d="M908.399 634.8L900.799 693.2C913.199 712.4 915.599 753.6 891.199 755.6C842.666 760.133 812.399 736.133 800.399 683.6C810.799 674.4 813.999 641.6 814.799 594L908.399 634.8Z"
                        fill="#FFC5C7"
                      />
                      <path
                        id="&#60;Path&#62;_117"
                        d="M806 675.2C812 660.4 814.399 632 814.799 594L908.4 634.8L903.599 672.4C892.666 677.2 860.133 678.133 806 675.2V675.2Z"
                        fill="#FE8A8B"
                      />
                    </g>
                    <g id="&#60;Group&#62;_106">
                      <path
                        id="&#60;Path&#62;_118"
                        d="M642.4 1012.8L621.2 1049.6C668.799 1096.4 685.6 1150 704.4 1192C722.8 1234 750.399 1272.8 755.599 1276.8C761.199 1280.8 775.999 1282.8 796.799 1286C817.599 1288.8 831.199 1298.8 841.999 1314.4C853.199 1330 864.799 1342 873.599 1350C882.799 1358 880.399 1367.6 863.999 1366C853.066 1364.67 838.666 1354.93 820.799 1336.8C828.799 1354.67 833.599 1368 835.199 1376.8C837.599 1390 853.2 1398.4 862.4 1418.8C872 1439.6 865.6 1450 860.4 1448.8C856.933 1448.27 853.6 1444.8 850.4 1438.4C840.266 1436 828.799 1430.53 815.999 1422C801.333 1423.33 790.133 1419.87 782.4 1411.6C772.266 1410.8 764.399 1407.6 758.799 1402C749.999 1393.2 717.6 1336.8 711.2 1322.8C704.4 1309.2 695.999 1302.4 657.599 1260.4C619.599 1218.8 536.4 1106.8 528.4 1081.6C520.4 1056.4 541.599 1001.6 557.999 979.6L642.4 1012.8Z"
                        fill="#FFC5C7"
                      />
                      <path
                        id="&#60;Path&#62;_119"
                        d="M838.4 1422C834.4 1417.6 830.4 1412.8 826.4 1408.8L823.2 1405.6C822.4 1404.4 821.6 1404 820 1402C818.4 1399.2 816.8 1396.8 815.6 1394C813.2 1388.4 811.2 1382.8 809.6 1376.8C812.8 1381.6 816 1387.2 818.8 1392C820.4 1394.8 822 1397.2 823.6 1399.6C824 1400 825.6 1401.2 826.4 1402.4L830 1405.6C838 1414.4 845.6 1424 849.6 1435.6C845.866 1430.8 842.133 1426.27 838.4 1422V1422Z"
                        fill="#FE8A8B"
                      />
                      <path
                        id="&#60;Path&#62;_120"
                        d="M800.8 1403.2C797.2 1397.6 794.8 1390.8 795.2 1383.6C798.4 1389.6 801.2 1395.2 804.8 1400.8C808 1406.4 812 1411.6 815.2 1418C809.2 1414.4 804.4 1409.2 800.8 1403.2V1403.2Z"
                        fill="#FE8A8B"
                      />
                      <path
                        id="&#60;Path&#62;_121"
                        d="M798.4 1420.4C791.466 1418.27 786.133 1415.33 782.4 1411.6C772.266 1410.8 764.4 1407.6 758.8 1402C755.2 1398.4 747.6 1386.8 739.6 1373.6C739.6 1373.6 749.2 1384 756.4 1392.8C762.8 1400.8 770.666 1403.47 780 1400.8L778 1389.6C784.133 1406.4 790.933 1416.67 798.4 1420.4V1420.4Z"
                        fill="#FE8A8B"
                      />
                      <path
                        id="&#60;Path&#62;_122"
                        d="M528 1053.6C528.4 1064 528.4 1072.4 544 1099.6C561.6 1130.4 666 1260 688.8 1284C711.6 1308 710.8 1309.6 724.4 1347.6C718.4 1336.8 713.2 1327.6 711.2 1322.8C704.4 1309.2 696 1302.4 657.6 1260.4C619.6 1218.8 536.4 1106.8 528.4 1081.6C526 1074.4 526 1064.4 528 1053.6V1053.6Z"
                        fill="#FE8A8B"
                      />
                      <path
                        id="&#60;Path&#62;_123"
                        d="M638 1021.2L621.2 1049.6C615.6 1043.73 609.066 1039.73 601.6 1037.6L610 1023.6L638 1021.2Z"
                        fill="#FE8A8B"
                      />
                      <path
                        id="&#60;Path&#62;_124"
                        d="M645.2 1039.6C555.2 1002.8 507.6 952.4 509.6 946C511.2 940 574.8 865.6 598 839.2C621.2 812.8 646 755.6 654.8 751.2C664 746.8 701.2 760 721.2 796.8C740.8 833.6 732 903.2 710 946C673.733 1011.33 652.133 1042.53 645.2 1039.6V1039.6Z"
                        fill="#160F29"
                      />
                      <path
                        id="&#60;Path&#62;_125"
                        d="M643.6 979.6L629.2 1008.8C676.4 961.6 709.733 920.4 729.2 885.2C725.6 907.2 718.8 928.8 710 946C673.733 1011.33 652.133 1042.53 645.2 1039.6C629.6 1033.6 615.6 1026.8 602.8 1020L643.6 979.6Z"
                        fill="#DCEDFF"
                      />
                    </g>
                    <g id="&#60;Group&#62;_107">
                      <g id="&#60;Group&#62;_108">
                        <path
                          id="&#60;Path&#62;_126"
                          d="M913.599 598C908.799 578.4 908.799 558.4 907.599 538.4C906.799 523.2 905.999 509.6 892.399 500.4C882.399 494 869.999 492.8 858.399 494C826.799 498 819.999 528.8 822.399 558C824.399 580 832.799 601.6 841.599 622C829.999 621.6 813.999 615.6 807.199 606C810.399 618.4 816.399 630 825.599 639.2C791.999 638.4 760.799 616.4 742.799 587.2C725.199 557.6 719.599 521.6 721.199 486.8C723.599 445.6 736.399 404 761.999 372.4C813.199 308.4 911.999 301.2 972.399 356C992.799 374.8 1008 400 1015.6 427.6C1025.2 463.6 1025.2 502.4 1017.6 538.8C1014.4 554 1012.8 566.8 1011.2 582C1010.8 586.8 1005.2 641.2 1008.4 640.8C987.199 642.4 962.799 638 948.399 621.6C954.799 632.8 960.399 643.6 966.799 654.8C939.999 650.4 920.399 625.2 913.599 598V598Z"
                          fill="#372D5B"
                        />
                      </g>
                      <path
                        id="&#60;Path&#62;_127"
                        d="M832.799 432.8C853.999 408.8 886.399 394 918.799 395.2C944.399 396 969.599 407.2 987.999 425.2C1006 443.2 997.199 462 995.599 484.8C995.199 492.4 997.599 527.2 995.199 544.8C988.399 594.4 950.399 641.2 939.199 652.8C928.399 664 907.199 672 877.199 656C857.466 645.6 834.133 621.467 807.199 583.6C779.199 598.4 760.799 537.2 773.999 524.4C782.799 515.2 793.999 518.4 803.599 524C804.799 490 809.199 459.6 832.799 432.8V432.8Z"
                        fill="#FFC5C7"
                      />
                      <path
                        id="&#60;Path&#62;_128"
                        d="M778.399 540.8C780.799 529.6 790.399 527.6 797.599 535.2C804.799 543.2 805.999 568.8 803.999 572.4C802.399 576 789.599 576 787.599 568C785.199 560.4 792.399 555.6 799.199 558C800.533 550.267 799.333 544 795.599 539.2C789.999 532 783.999 534.8 782.399 536.8C781.333 537.867 779.999 539.2 778.399 540.8V540.8Z"
                        fill="#FE8A8B"
                      />
                      <g id="&#60;Group&#62;_109">
                        <g id="&#60;Group&#62;_110">
                          <path
                            id="&#60;Path&#62;_129"
                            d="M855.6 491.6C858.533 488.933 861.466 486.533 864.4 484.4C867.333 482.267 870.533 480.267 874 478.4C877.6 476.8 881.2 475.2 884.8 474.8C888.4 474.8 892.4 474.8 895.6 476.4V476.8C894 477.6 892 478 890.4 478.8L885.2 480C882 480.8 878.8 481.2 875.2 482.4C872 483.467 868.8 484.8 865.6 486.4C862.4 488 859.2 490 856 492L855.6 491.6Z"
                            fill="#3F1751"
                          />
                        </g>
                        <g id="&#60;Group&#62;_111">
                          <path
                            id="&#60;Path&#62;_130"
                            d="M982 478.4C979.066 476.8 975.866 475.733 972.4 475.2C969.2 474.4 965.866 474 962.4 474C958.8 473.6 955.2 474.4 952 474.4C948.4 474.4 945.2 474.4 941.2 474V473.6C944.4 471.2 948 470 951.6 469.2C955.6 468.8 959.2 469.2 962.8 469.6C966.533 470.133 970.133 471.2 973.6 472.8C976.8 474 980 476 982.4 478L982 478.4Z"
                            fill="#3F1751"
                          />
                        </g>
                        <g id="&#60;Group&#62;_112">
                          <path
                            id="&#60;Path&#62;_131"
                            d="M884.4 520.8C887.272 520.8 889.6 516.86 889.6 512C889.6 507.14 887.272 503.2 884.4 503.2C881.528 503.2 879.2 507.14 879.2 512C879.2 516.86 881.528 520.8 884.4 520.8Z"
                            fill="#3F1751"
                          />
                        </g>
                        <g id="&#60;Group&#62;_113">
                          <path
                            id="&#60;Path&#62;_132"
                            d="M961.6 514.8C964.03 514.8 966 510.86 966 506C966 501.14 964.03 497.2 961.6 497.2C959.17 497.2 957.2 501.14 957.2 506C957.2 510.86 959.17 514.8 961.6 514.8Z"
                            fill="#3F1751"
                          />
                        </g>
                        <path
                          id="&#60;Path&#62;_133"
                          d="M913.6 558.4C920.4 563.2 930 562.8 937.2 558.4C936.4 562 934.8 565.6 931.2 566.8C927.6 568.4 923.6 568 920 566.4C916.8 564.8 915.6 561.6 913.6 558.4Z"
                          fill="#FE8A8B"
                        />
                        <g id="&#60;Group&#62;_114">
                          <path
                            id="&#60;Path&#62;_134"
                            d="M887.2 586.8C903.2 587.2 941.2 584 954 582.4C948.8 598.4 936 614.4 918 614.4C901.6 614.4 891.2 601.2 887.2 586.8Z"
                            fill="#C31F2A"
                          />
                          <path
                            id="&#60;Path&#62;_135"
                            d="M901.6 609.2C908 607.2 914.4 608.4 917.2 609.2C922.8 605.2 929.733 604.533 938 607.2C932.4 611.6 925.6 614.4 918 614.4C911.6 614.4 906.4 612.4 901.6 609.2Z"
                            fill="#FB8384"
                          />
                          <path
                            id="&#60;Path&#62;_136"
                            d="M943.2 596C926.8 600.8 909.2 599.6 894 594C894 591.6 893.866 589.2 893.6 586.8C909.6 586.4 934.8 584.4 948.4 583.2C947.2 587.6 945.6 592 943.2 596V596Z"
                            fill="#160F29"
                          />
                        </g>
                      </g>
                      <g id="&#60;Group&#62;_115">
                        <path
                          id="&#60;Path&#62;_137"
                          d="M829.199 576.8C802.399 550.8 783.599 515.6 781.199 478C777.599 412.4 827.199 360 890.399 350.8C920.399 346.4 962.799 351.6 987.199 371.2C1012 391.2 1012.8 438.8 1001.2 465.2C991.599 463.6 979.999 458.8 970.799 462C969.199 449.2 966.399 434.4 961.199 422.4C961.999 434.8 961.599 447.2 957.199 459.2C941.999 460.4 926.799 461.2 911.599 462C911.199 448.4 907.199 435.2 900.799 423.2C899.999 434.4 899.999 444 895.199 454.4C871.199 464.4 845.599 470.4 819.599 471.2C809.999 506 813.199 545.2 829.199 576.8Z"
                          fill="#372D5B"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="message">
              <g id="&#60;Group&#62;_116">
                <path
                  id="&#60;Path&#62;_138"
                  d="M1449.2 822.4C1541.54 822.4 1616.4 747.542 1616.4 655.2C1616.4 562.858 1541.54 488 1449.2 488C1356.86 488 1282 562.858 1282 655.2C1282 747.542 1356.86 822.4 1449.2 822.4Z"
                  fill="#E67471"
                />
              </g>
              <g id="&#60;Group&#62;_117">
                <g id="&#60;Group&#62;_118">
                  <path
                    id="&#60;Path&#62;_139"
                    d="M1532 589.6H1366.8C1359.29 589.6 1353.2 595.689 1353.2 603.2V706.8C1353.2 714.311 1359.29 720.4 1366.8 720.4H1532C1539.51 720.4 1545.6 714.311 1545.6 706.8V603.2C1545.6 595.689 1539.51 589.6 1532 589.6Z"
                    fill="#DBE9FF"
                  />
                </g>
                <g id="&#60;Group&#62;_119">
                  <path
                    id="&#60;Path&#62;_140"
                    d="M1353.2 706.8L1449.2 646.4L1545.6 706.8C1545.6 714.4 1539.2 720.8 1531.6 720.8H1366.8C1359.2 720.8 1353.2 714.4 1353.2 706.8V706.8Z"
                    fill="#CDDCF4"
                  />
                </g>
                <g id="&#60;Group&#62;_120">
                  <path
                    id="&#60;Path&#62;_141"
                    d="M1366.8 589.6H1531.6C1539.2 589.6 1545.6 596 1545.6 603.6L1449.2 664L1353.2 603.6C1353.2 596 1359.2 589.6 1366.8 589.6V589.6Z"
                    fill="#5470D3"
                  />
                </g>
              </g>
            </g>
            <g id="setting">
              <g id="&#60;Group&#62;_121">
                <path
                  id="&#60;Path&#62;_142"
                  d="M2634.4 1062.8C2726.74 1062.8 2801.6 987.942 2801.6 895.6C2801.6 803.258 2726.74 728.4 2634.4 728.4C2542.06 728.4 2467.2 803.258 2467.2 895.6C2467.2 987.942 2542.06 1062.8 2634.4 1062.8Z"
                  fill="#E67471"
                />
              </g>
              <g id="&#60;Group&#62;_122">
                <g id="cog">
                  <g id="&#60;Group&#62;_123">
                    <g id="&#60;Group&#62;_124">
                      <g id="&#60;Group&#62;_125">
                        <path
                          id="&#60;Compound Path&#62;"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2685.2 867.2V886C2685.2 888 2683.6 890 2681.2 890H2664.4C2662.8 896.8 2660 902.8 2656.8 908.4L2668.4 920.4C2670 922 2670 924.4 2668.4 926.4L2655.2 939.6C2653.6 941.2 2651.2 941.2 2649.6 939.6L2637.6 927.6C2632 931.2 2625.6 933.6 2619.2 935.2V952C2619.2 954.4 2617.2 956 2615.2 956H2596.4C2594 956 2592 954.4 2592 952V935.2C2585.6 933.6 2579.2 931.2 2573.6 927.6L2562 939.6C2560.4 941.2 2557.6 941.2 2556 939.6L2542.8 926.4C2541.2 924.4 2541.2 922 2542.8 920.4L2554.8 908.4C2551.2 902.8 2548.4 896.8 2547.2 890H2530.4C2528 890 2526 888 2526 886V867.2C2526 864.8 2528 863.2 2530.4 863.2H2547.2C2548.4 856.4 2551.2 850.4 2554.8 844.8L2542.8 832.8C2541.2 831.2 2541.2 828.4 2542.8 826.8L2556 813.6C2557.6 812 2560.4 812 2562 813.6L2573.6 825.6C2579.2 822 2585.6 819.6 2592 818V801.2C2592 798.8 2594 796.8 2596.4 796.8H2615.2C2617.2 796.8 2619.2 798.8 2619.2 801.2V818C2625.6 819.6 2632 822 2637.6 825.6L2649.6 813.6C2651.2 812 2653.6 812 2655.2 813.6L2668.4 826.8C2670 828.4 2670 831.2 2668.4 832.8L2656.8 844.8C2660 850.4 2662.8 856.4 2664.4 863.2H2681.2C2683.6 863.2 2685.2 864.8 2685.2 867.2ZM2631.6 876.4C2631.6 862.4 2620 850.8 2605.6 850.8C2591.6 850.8 2580 862.4 2580 876.4C2580 890.8 2591.6 902.4 2605.6 902.4C2620 902.4 2631.6 890.8 2631.6 876.4Z"
                          fill="#DBE9FF"
                        />
                      </g>
                    </g>
                    <g id="&#60;Group&#62;_126">
                      <g id="&#60;Group&#62;_127">
                        <path
                          id="&#60;Compound Path&#62;_2"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2641.6 876.4C2641.6 896.4 2625.6 912.4 2605.6 912.4C2586 912.4 2569.6 896.4 2569.6 876.4C2569.6 856.8 2586 840.8 2605.6 840.8C2625.6 840.8 2641.6 856.8 2641.6 876.4ZM2627.6 876.4C2627.6 864.4 2617.6 854.8 2605.6 854.8C2593.6 854.8 2583.6 864.4 2583.6 876.4C2583.6 888.8 2593.6 898.4 2605.6 898.4C2617.6 898.4 2627.6 888.8 2627.6 876.4Z"
                          fill="#5470D3"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="cogSmall">
                  <g id="&#60;Group&#62;_128">
                    <g id="&#60;Group&#62;_129">
                      <path
                        id="&#60;Compound Path&#62;_3"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2755.2 938.8V948.4C2755.2 949.6 2754 950.4 2752.8 950.4H2744.4C2743.6 953.867 2742.27 957.066 2740.4 960L2746.4 966C2747.2 966.8 2747.2 968 2746.4 968.8L2739.6 975.6C2738.8 976.4 2737.6 976.4 2736.8 975.6L2730.8 969.6C2727.87 971.466 2724.67 972.8 2721.2 973.6V982C2721.2 983.2 2720.4 984.4 2719.2 984.4H2709.6C2708.4 984.4 2707.6 983.2 2707.6 982V973.6C2704.13 972.8 2700.93 971.466 2698 969.6L2692 975.6C2691.2 976.4 2689.6 976.4 2688.8 975.6L2682.4 968.8C2681.2 968 2681.2 966.8 2682.4 966L2688.4 960C2686.53 957.066 2685.2 953.867 2684.4 950.4H2676C2674.8 950.4 2673.6 949.6 2673.6 948.4V938.8C2673.6 937.6 2674.8 936.8 2676 936.8H2684.4C2685.2 933.333 2686.53 930.133 2688.4 927.2L2682.4 921.2C2681.2 920.4 2681.2 918.8 2682.4 918L2688.8 911.6C2689.6 910.4 2691.2 910.4 2692 911.6L2698 917.6C2700.93 915.733 2704.13 914.4 2707.6 913.6V904.8C2707.6 904 2708.4 902.8 2709.6 902.8H2719.2C2720.4 902.8 2721.2 904 2721.2 904.8V913.6C2724.67 914.4 2727.87 915.733 2730.8 917.6L2736.8 911.6C2737.6 910.4 2738.8 910.4 2739.6 911.6L2746.4 918C2747.2 918.8 2747.2 920.4 2746.4 921.2L2740.4 927.2C2742.27 930.133 2743.6 933.333 2744.4 936.8H2752.8C2754 936.8 2755.2 937.6 2755.2 938.8ZM2727.6 943.6C2727.6 936.4 2721.6 930.4 2714.4 930.4C2707.2 930.4 2701.2 936.4 2701.2 943.6C2701.2 950.8 2707.2 956.8 2714.4 956.8C2721.6 956.8 2727.6 950.8 2727.6 943.6Z"
                        fill="#DBE9FF"
                      />
                    </g>
                  </g>
                  <g id="&#60;Group&#62;_130">
                    <g id="&#60;Group&#62;_131">
                      <path
                        id="&#60;Compound Path&#62;_4"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2732.8 943.6C2732.8 953.6 2724.4 962 2714.4 962C2704.4 962 2696 953.6 2696 943.6C2696 933.6 2704.4 925.2 2714.4 925.2C2724.4 925.2 2732.8 933.6 2732.8 943.6ZM2725.6 943.6C2725.6 937.2 2720.4 932.4 2714.4 932.4C2708 932.4 2703.2 937.2 2703.2 943.6C2703.2 949.6 2708 954.8 2714.4 954.8C2720.4 954.8 2725.6 949.6 2725.6 943.6Z"
                        fill="#5470D3"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="calender">
              <g id="&#60;Group&#62;_132">
                <path
                  id="&#60;Path&#62;_143"
                  d="M1880.4 589.6C1972.74 589.6 2047.6 514.742 2047.6 422.4C2047.6 330.058 1972.74 255.2 1880.4 255.2C1788.06 255.2 1713.2 330.058 1713.2 422.4C1713.2 514.742 1788.06 589.6 1880.4 589.6Z"
                  fill="#E67471"
                />
              </g>
              <g id="&#60;Group&#62;_133">
                <path
                  id="&#60;Path&#62;_144"
                  d="M1963.2 343.6H1797.2C1789.91 343.6 1784 349.51 1784 356.8V494.8C1784 502.09 1789.91 508 1797.2 508H1963.2C1970.49 508 1976.4 502.09 1976.4 494.8V356.8C1976.4 349.51 1970.49 343.6 1963.2 343.6Z"
                  fill="#160F29"
                />
                <g id="&#60;Group&#62;_134">
                  <path
                    id="&#60;Path&#62;_145"
                    d="M1784 356.8C1784 349.6 1790 343.6 1797.2 343.6H1963.2C1970.4 343.6 1976.4 349.6 1976.4 356.8V378H1784V356.8Z"
                    fill="#5470D3"
                  />
                </g>
                <g id="&#60;Group&#62;_135">
                  <g id="&#60;Group&#62;_136">
                    <g id="&#60;Group&#62;_137">
                      <g id="&#60;Group&#62;_138">
                        <path
                          id="&#60;Path&#62;_146"
                          d="M1926.4 347.2V340.4C1926.4 338 1928.4 336.4 1930.4 336.4C1932.8 336.4 1934.4 338 1934.4 340.4V347.2C1934.4 349.2 1932.8 351.2 1930.4 351.2C1928.4 351.2 1926.4 349.2 1926.4 347.2Z"
                          fill="#160F29"
                        />
                        <path
                          id="&#60;Path&#62;_147"
                          d="M1937.6 347.2V340.4C1937.6 338 1939.6 336.4 1941.6 336.4C1943.6 336.4 1945.6 338 1945.6 340.4V347.2C1945.6 349.2 1943.6 351.2 1941.6 351.2C1939.6 351.2 1937.6 349.2 1937.6 347.2Z"
                          fill="#160F29"
                        />
                      </g>
                    </g>
                    <g id="&#60;Group&#62;_139">
                      <g id="&#60;Group&#62;_140">
                        <path
                          id="&#60;Path&#62;_148"
                          d="M1814.8 347.2V340.4C1814.8 338 1816.8 336.4 1818.8 336.4C1821.2 336.4 1822.8 338 1822.8 340.4V347.2C1822.8 349.2 1821.2 351.2 1818.8 351.2C1816.8 351.2 1814.8 349.2 1814.8 347.2Z"
                          fill="#160F29"
                        />
                        <path
                          id="&#60;Path&#62;_149"
                          d="M1826 347.2V340.4C1826 338 1828 336.4 1830 336.4C1832 336.4 1834 338 1834 340.4V347.2C1834 349.2 1832 351.2 1830 351.2C1828 351.2 1826 349.2 1826 347.2Z"
                          fill="#160F29"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="&#60;Group&#62;_141">
                  <g id="&#60;Group&#62;_142">
                    <g id="&#60;Group&#62;_143">
                      <path
                        id="&#60;Path&#62;_150"
                        d="M1902.8 398.8H1892.4C1890.63 398.8 1889.2 400.233 1889.2 402V412.4C1889.2 414.167 1890.63 415.6 1892.4 415.6H1902.8C1904.57 415.6 1906 414.167 1906 412.4V402C1906 400.233 1904.57 398.8 1902.8 398.8Z"
                        fill="#DBE9FF"
                      />
                    </g>
                    <g id="&#60;Group&#62;_144">
                      <path
                        id="&#60;Path&#62;_151"
                        d="M1863.2 398.8H1852.8C1851.03 398.8 1849.6 400.233 1849.6 402V412.4C1849.6 414.167 1851.03 415.6 1852.8 415.6H1863.2C1864.97 415.6 1866.4 414.167 1866.4 412.4V402C1866.4 400.233 1864.97 398.8 1863.2 398.8Z"
                        fill="#DBE9FF"
                      />
                    </g>
                    <g id="&#60;Group&#62;_145">
                      <path
                        id="&#60;Path&#62;_152"
                        d="M1823.6 398.8H1813.2C1811.43 398.8 1810 400.233 1810 402V412.4C1810 414.167 1811.43 415.6 1813.2 415.6H1823.6C1825.37 415.6 1826.8 414.167 1826.8 412.4V402C1826.8 400.233 1825.37 398.8 1823.6 398.8Z"
                        fill="#DBE9FF"
                      />
                    </g>
                    <g id="&#60;Group&#62;_146">
                      <path
                        id="&#60;Path&#62;_153"
                        d="M1942.4 432.8H1932C1930.23 432.8 1928.8 434.233 1928.8 436V446.4C1928.8 448.167 1930.23 449.6 1932 449.6H1942.4C1944.17 449.6 1945.6 448.167 1945.6 446.4V436C1945.6 434.233 1944.17 432.8 1942.4 432.8Z"
                        fill="#DBE9FF"
                      />
                    </g>
                    <g id="&#60;Group&#62;_147">
                      <path
                        id="&#60;Path&#62;_154"
                        d="M1902.8 432.8H1892.4C1890.63 432.8 1889.2 434.233 1889.2 436V446.4C1889.2 448.167 1890.63 449.6 1892.4 449.6H1902.8C1904.57 449.6 1906 448.167 1906 446.4V436C1906 434.233 1904.57 432.8 1902.8 432.8Z"
                        fill="#DBE9FF"
                      />
                    </g>
                    <g id="&#60;Group&#62;_148">
                      <path
                        id="&#60;Path&#62;_155"
                        d="M1863.2 432.8H1852.8C1851.03 432.8 1849.6 434.233 1849.6 436V446.4C1849.6 448.167 1851.03 449.6 1852.8 449.6H1863.2C1864.97 449.6 1866.4 448.167 1866.4 446.4V436C1866.4 434.233 1864.97 432.8 1863.2 432.8Z"
                        fill="#DBE9FF"
                      />
                    </g>
                    <g id="&#60;Group&#62;_149">
                      <path
                        id="&#60;Path&#62;_156"
                        d="M1823.6 432.8H1813.2C1811.43 432.8 1810 434.233 1810 436V446.4C1810 448.167 1811.43 449.6 1813.2 449.6H1823.6C1825.37 449.6 1826.8 448.167 1826.8 446.4V436C1826.8 434.233 1825.37 432.8 1823.6 432.8Z"
                        fill="#DBE9FF"
                      />
                    </g>
                    <g id="&#60;Group&#62;_150">
                      <path
                        id="&#60;Path&#62;_157"
                        d="M1942.4 466.8H1932C1930.23 466.8 1928.8 468.233 1928.8 470V480.4C1928.8 482.167 1930.23 483.6 1932 483.6H1942.4C1944.17 483.6 1945.6 482.167 1945.6 480.4V470C1945.6 468.233 1944.17 466.8 1942.4 466.8Z"
                        fill="#DBE9FF"
                      />
                    </g>
                    <g id="&#60;Group&#62;_151">
                      <path
                        id="&#60;Path&#62;_158"
                        d="M1902.8 466.8H1892.4C1890.63 466.8 1889.2 468.233 1889.2 470V480.4C1889.2 482.167 1890.63 483.6 1892.4 483.6H1902.8C1904.57 483.6 1906 482.167 1906 480.4V470C1906 468.233 1904.57 466.8 1902.8 466.8Z"
                        fill="#DBE9FF"
                      />
                    </g>
                    <g id="&#60;Group&#62;_152">
                      <path
                        id="&#60;Path&#62;_159"
                        d="M1863.2 466.8H1852.8C1851.03 466.8 1849.6 468.233 1849.6 470V480.4C1849.6 482.167 1851.03 483.6 1852.8 483.6H1863.2C1864.97 483.6 1866.4 482.167 1866.4 480.4V470C1866.4 468.233 1864.97 466.8 1863.2 466.8Z"
                        fill="#DBE9FF"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="&#60;Group&#62;_153">
              <g id="&#60;Group&#62;_154">
                <g id="&#60;Group&#62;_155">
                  <g id="clock">
                    <g id="&#60;Group&#62;_156">
                      <path
                        id="&#60;Path&#62;_160"
                        d="M2366.2 642.4C2458.54 642.4 2533.4 567.542 2533.4 475.2C2533.4 382.858 2458.54 308 2366.2 308C2273.86 308 2199 382.858 2199 475.2C2199 567.542 2273.86 642.4 2366.2 642.4Z"
                        fill="#E67471"
                      />
                    </g>
                    <path
                      id="&#60;Path&#62;_161"
                      d="M2366.2 589.6C2429.38 589.6 2480.6 538.381 2480.6 475.2C2480.6 412.019 2429.38 360.8 2366.2 360.8C2303.02 360.8 2251.8 412.019 2251.8 475.2C2251.8 538.381 2303.02 589.6 2366.2 589.6Z"
                      fill="#160F29"
                    />
                    <g id="&#60;Group&#62;_157">
                      <path
                        id="&#60;Compound Path&#62;_5"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2480.6 475.2C2480.6 538.4 2429.4 589.6 2366.2 589.6C2303 589.6 2251.8 538.4 2251.8 475.2C2251.8 411.6 2303 360.4 2366.2 360.4C2429.4 360.4 2480.6 411.6 2480.6 475.2ZM2463.8 484.8H2447.8C2445 484.8 2442.6 482.4 2442.6 479.2V470.8C2442.6 467.6 2445 465.6 2447.8 465.6H2463.8C2459 418.8 2422.2 382 2375.8 377.6V393.2C2375.8 396 2373.4 398.4 2370.6 398.4H2361.8C2359 398.4 2356.6 396 2356.6 393.2V377.6C2310.2 382 2273 418.8 2268.6 465.6H2284.2C2287 465.6 2289.4 467.6 2289.4 470.8V479.2C2289.4 482.4 2287 484.8 2284.2 484.8H2268.6C2273 531.2 2310.2 568 2356.6 572.4V556.8C2356.6 554 2359 551.6 2361.8 551.6H2370.6C2373.4 551.6 2375.8 554 2375.8 556.8V572.4C2422.2 568 2459 531.2 2463.8 484.8Z"
                        fill="#5470D3"
                      />
                    </g>
                  </g>
                </g>
                <text
                  id="24/7"
                  fill="#fffff"
                  fontFamily="Inter"
                  fontSize="80"
                  letterSpacing="0em"
                >
                  <tspan x="-61.2002" y="-2.40909">
                    24/7&#10;
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </g>
        <defs>
          <clipPath id="clip0_114_2">
            <rect width="3000" height="2000" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Svg;
