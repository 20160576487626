import React from "react";
import Navbar from "../Navbar";
import Footer from "./Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Showcase = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Navbar color="black" />
      <div className="showcaseCircle"></div>

      <section className="showcase">
        <div className="container">
          <div
            id={`carouselExampleControls1`}
            className="carousel slide showCarousel"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target={`#carouselExampleIndicators1`}
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target={`#carouselExampleIndicators1`}
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={process.env.PUBLIC_URL + "Images/EmealL.png"}
                  className="d-block w-100"
                  alt="Portfolio"
                />
              </div>
              <div className="carousel-item">
                <img
                  src={process.env.PUBLIC_URL + "Images/EmealS.png"}
                  className="d-block w-100"
                  alt="Portfolio"
                />
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target={`#carouselExampleControls1`}
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target={`#carouselExampleControls1`}
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
            </button>
          </div>

          <h1 className="showH1">E Meals</h1>

          <div className="row descDiv">
            <div className="col-xl-6 col-md-6 col-sm-10">
              <h1>App Overview</h1>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-10">
              <p className="descrip">
                <ul className="check-list">
                  <li>
                    For busy families, dinner time is quickly resolved with
                    EMeals.{" "}
                  </li>
                  <li>
                    There will be sufficient culinary ideas to appease even the
                    pickiest eaters with 15 distinct meal and diet plans.{" "}
                  </li>
                  <li>
                    Spend less time by having one of our online grocery delivery
                    or pickup partners get your customized shopping list's
                    components.
                  </li>
                  <li>
                    Weekly meal plans offer the variety and flexibility for you
                    to pick the recipes that best fit your needs each week.
                  </li>
                  <li>
                    Shop yourself or skip the grocery store by sending your
                    shopping list directly to Amazon, Walmart, Kroger, Instacart
                    or Ship.
                  </li>
                </ul>
              </p>
              <a
                href="https://play.google.com/store/apps/details?id=com.emeals"
                target="_blank"
              >
                <button className="storeBtn">App Store</button>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.emeals"
                target="_blank"
              >
                <button className="storeBtn">Google Play</button>
              </a>
            </div>
          </div>
          <div className="row descDiv">
            <div className="col-xl-6 col-md-6 col-sm-10">
              <h1>Client Requirement </h1>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-10">
              <p className="descrip">
                <ul className="fire-list">
                  <li>
                    This idea was first presented by the client. And now, as
                    client needs to release this incredible online grocery app
                    with a meal plan. The client is undoubtedly interested in a
                    long-term partnership with Justcode.
                  </li>
                  <li>
                    Our team has already been presented with a new project for
                    automation and inventory management. Depending on the
                    budget, we have choices to make it totally automated or
                    semi-manual.
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className="row descDiv">
            <div className="col-xl-6 col-md-6 col-sm-10">
              <h1>Final Takeaway </h1>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-10">
              <p className="descrip">
                <ul className="success-list">
                  <li>
                    The client intended to place the project in development as
                    quickly as possible, so the full project was finished in a
                    month.
                  </li>
                  <li>
                    To make emeal a reality, our designers collaborated with the
                    client around-the-clock.
                  </li>
                  <li>
                    Emeal was successfully finished with regular, easy, and
                    quick communication and an end - to - end feedback loop.
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <h1 className="showH1">More Projects</h1>
          <div className="showSlider">
            <Slider {...settings}>
              <div className="imgShow">
                <img
                  className="w-100"
                  src={process.env.PUBLIC_URL + "Images/ChurchhomeL.png"}
                  alt="images"
                />
              </div>
              <div className="imgShow">
                <img
                  className="w-100"
                  src={process.env.PUBLIC_URL + "Images/ChurchhomeL.png"}
                  alt="images"
                />
              </div>
              <div className="imgShow">
                <img
                  className="w-100"
                  src={process.env.PUBLIC_URL + "Images/ChurchhomeL.png"}
                  alt="images"
                />
              </div>
              <div className="imgShow">
                <img
                  className="w-100"
                  src={process.env.PUBLIC_URL + "Images/ChurchhomeL.png"}
                  alt="images"
                />
              </div>
            </Slider>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Showcase;
